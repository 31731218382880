
import React, { Suspense,  useState,useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree, useUpdate, createPortal} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Controls, useControl } from 'react-three-gui';
import  OControls  from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
import {VRCanvas, DefaultXRControllers} from "@react-three/xr";
//import ChatBot from "aws-amplify-react/src/Interactions";
import { useFBO } from './useFBO';
import { PerspectiveCamera } from './PerspetiveCamera';
import {Box} from './Shapes';
extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;



const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const cnoise = `
vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
vec4 fade(vec4 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}


// Classic Perlin noise, periodic version
float cnoise(vec4 P, vec4 rep){
  vec4 Pi0 = mod(floor(P), rep); // Integer part modulo rep
  vec4 Pi1 = mod(Pi0 + 1.0, rep); // Integer part + 1 mod rep
  vec4 Pf0 = fract(P); // Fractional part for interpolation
  vec4 Pf1 = Pf0 - 1.0; // Fractional part - 1.0
  vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
  vec4 iy = vec4(Pi0.yy, Pi1.yy);
  vec4 iz0 = vec4(Pi0.zzzz);
  vec4 iz1 = vec4(Pi1.zzzz);
  vec4 iw0 = vec4(Pi0.wwww);
  vec4 iw1 = vec4(Pi1.wwww);

  vec4 ixy = permute(permute(ix) + iy);
  vec4 ixy0 = permute(ixy + iz0);
  vec4 ixy1 = permute(ixy + iz1);
  vec4 ixy00 = permute(ixy0 + iw0);
  vec4 ixy01 = permute(ixy0 + iw1);
  vec4 ixy10 = permute(ixy1 + iw0);
  vec4 ixy11 = permute(ixy1 + iw1);

  vec4 gx00 = ixy00 / 7.0;
  vec4 gy00 = floor(gx00) / 7.0;
  vec4 gz00 = floor(gy00) / 6.0;
  gx00 = fract(gx00) - 0.5;
  gy00 = fract(gy00) - 0.5;
  gz00 = fract(gz00) - 0.5;
  vec4 gw00 = vec4(0.75) - abs(gx00) - abs(gy00) - abs(gz00);
  vec4 sw00 = step(gw00, vec4(0.0));
  gx00 -= sw00 * (step(0.0, gx00) - 0.5);
  gy00 -= sw00 * (step(0.0, gy00) - 0.5);

  vec4 gx01 = ixy01 / 7.0;
  vec4 gy01 = floor(gx01) / 7.0;
  vec4 gz01 = floor(gy01) / 6.0;
  gx01 = fract(gx01) - 0.5;
  gy01 = fract(gy01) - 0.5;
  gz01 = fract(gz01) - 0.5;
  vec4 gw01 = vec4(0.75) - abs(gx01) - abs(gy01) - abs(gz01);
  vec4 sw01 = step(gw01, vec4(0.0));
  gx01 -= sw01 * (step(0.0, gx01) - 0.5);
  gy01 -= sw01 * (step(0.0, gy01) - 0.5);

  vec4 gx10 = ixy10 / 7.0;
  vec4 gy10 = floor(gx10) / 7.0;
  vec4 gz10 = floor(gy10) / 6.0;
  gx10 = fract(gx10) - 0.5;
  gy10 = fract(gy10) - 0.5;
  gz10 = fract(gz10) - 0.5;
  vec4 gw10 = vec4(0.75) - abs(gx10) - abs(gy10) - abs(gz10);
  vec4 sw10 = step(gw10, vec4(0.0));
  gx10 -= sw10 * (step(0.0, gx10) - 0.5);
  gy10 -= sw10 * (step(0.0, gy10) - 0.5);

  vec4 gx11 = ixy11 / 7.0;
  vec4 gy11 = floor(gx11) / 7.0;
  vec4 gz11 = floor(gy11) / 6.0;
  gx11 = fract(gx11) - 0.5;
  gy11 = fract(gy11) - 0.5;
  gz11 = fract(gz11) - 0.5;
  vec4 gw11 = vec4(0.75) - abs(gx11) - abs(gy11) - abs(gz11);
  vec4 sw11 = step(gw11, vec4(0.0));
  gx11 -= sw11 * (step(0.0, gx11) - 0.5);
  gy11 -= sw11 * (step(0.0, gy11) - 0.5);

  vec4 g0000 = vec4(gx00.x,gy00.x,gz00.x,gw00.x);
  vec4 g1000 = vec4(gx00.y,gy00.y,gz00.y,gw00.y);
  vec4 g0100 = vec4(gx00.z,gy00.z,gz00.z,gw00.z);
  vec4 g1100 = vec4(gx00.w,gy00.w,gz00.w,gw00.w);
  vec4 g0010 = vec4(gx10.x,gy10.x,gz10.x,gw10.x);
  vec4 g1010 = vec4(gx10.y,gy10.y,gz10.y,gw10.y);
  vec4 g0110 = vec4(gx10.z,gy10.z,gz10.z,gw10.z);
  vec4 g1110 = vec4(gx10.w,gy10.w,gz10.w,gw10.w);
  vec4 g0001 = vec4(gx01.x,gy01.x,gz01.x,gw01.x);
  vec4 g1001 = vec4(gx01.y,gy01.y,gz01.y,gw01.y);
  vec4 g0101 = vec4(gx01.z,gy01.z,gz01.z,gw01.z);
  vec4 g1101 = vec4(gx01.w,gy01.w,gz01.w,gw01.w);
  vec4 g0011 = vec4(gx11.x,gy11.x,gz11.x,gw11.x);
  vec4 g1011 = vec4(gx11.y,gy11.y,gz11.y,gw11.y);
  vec4 g0111 = vec4(gx11.z,gy11.z,gz11.z,gw11.z);
  vec4 g1111 = vec4(gx11.w,gy11.w,gz11.w,gw11.w);

  vec4 norm00 = taylorInvSqrt(vec4(dot(g0000, g0000), dot(g0100, g0100), dot(g1000, g1000), dot(g1100, g1100)));
  g0000 *= norm00.x;
  g0100 *= norm00.y;
  g1000 *= norm00.z;
  g1100 *= norm00.w;

  vec4 norm01 = taylorInvSqrt(vec4(dot(g0001, g0001), dot(g0101, g0101), dot(g1001, g1001), dot(g1101, g1101)));
  g0001 *= norm01.x;
  g0101 *= norm01.y;
  g1001 *= norm01.z;
  g1101 *= norm01.w;

  vec4 norm10 = taylorInvSqrt(vec4(dot(g0010, g0010), dot(g0110, g0110), dot(g1010, g1010), dot(g1110, g1110)));
  g0010 *= norm10.x;
  g0110 *= norm10.y;
  g1010 *= norm10.z;
  g1110 *= norm10.w;

  vec4 norm11 = taylorInvSqrt(vec4(dot(g0011, g0011), dot(g0111, g0111), dot(g1011, g1011), dot(g1111, g1111)));
  g0011 *= norm11.x;
  g0111 *= norm11.y;
  g1011 *= norm11.z;
  g1111 *= norm11.w;

  float n0000 = dot(g0000, Pf0);
  float n1000 = dot(g1000, vec4(Pf1.x, Pf0.yzw));
  float n0100 = dot(g0100, vec4(Pf0.x, Pf1.y, Pf0.zw));
  float n1100 = dot(g1100, vec4(Pf1.xy, Pf0.zw));
  float n0010 = dot(g0010, vec4(Pf0.xy, Pf1.z, Pf0.w));
  float n1010 = dot(g1010, vec4(Pf1.x, Pf0.y, Pf1.z, Pf0.w));
  float n0110 = dot(g0110, vec4(Pf0.x, Pf1.yz, Pf0.w));
  float n1110 = dot(g1110, vec4(Pf1.xyz, Pf0.w));
  float n0001 = dot(g0001, vec4(Pf0.xyz, Pf1.w));
  float n1001 = dot(g1001, vec4(Pf1.x, Pf0.yz, Pf1.w));
  float n0101 = dot(g0101, vec4(Pf0.x, Pf1.y, Pf0.z, Pf1.w));
  float n1101 = dot(g1101, vec4(Pf1.xy, Pf0.z, Pf1.w));
  float n0011 = dot(g0011, vec4(Pf0.xy, Pf1.zw));
  float n1011 = dot(g1011, vec4(Pf1.x, Pf0.y, Pf1.zw));
  float n0111 = dot(g0111, vec4(Pf0.x, Pf1.yzw));
  float n1111 = dot(g1111, Pf1);

  vec4 fade_xyzw = fade(Pf0);
  vec4 n_0w = mix(vec4(n0000, n1000, n0100, n1100), vec4(n0001, n1001, n0101, n1101), fade_xyzw.w);
  vec4 n_1w = mix(vec4(n0010, n1010, n0110, n1110), vec4(n0011, n1011, n0111, n1111), fade_xyzw.w);
  vec4 n_zw = mix(n_0w, n_1w, fade_xyzw.z);
  vec2 n_yzw = mix(n_zw.xy, n_zw.zw, fade_xyzw.y);
  float n_xyzw = mix(n_yzw.x, n_yzw.y, fade_xyzw.x);
  return 2.2 * n_xyzw;
}
`;


function Portal ({position}) {
    const { size, gl, scene, camera } = useThree();
    const cam = useRef();
    const scene2 = React.useMemo(() => {
        const scene = new THREE.Scene();
        //scene.background = new THREE.Color();
        return scene;
    }, []);

    const target = useMemo(() => {
        let target = new THREE.WebGLRenderTarget(200, 200, {});
        return target;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useFrame((state) => {

            cam.current.lookAt(position)
            //cam.current.position.z = 5 + Math.sin(state.clock.getElapsedTime() * 1.5) * 2
            state.gl.setRenderTarget(target);
            state.gl.render(scene, cam.current);
            state.gl.setRenderTarget(null);

    }, 1);

    return <>

        <PerspectiveCamera ref={cam} position={[0, 0, 0]} default={false} />
        {createPortal(<SpinningThing />, scene)}
        <mesh>

            <circleGeometry attach="geometry" args={[ 3, 32]} />
            <meshStandardMaterial  attach="material" map={target.texture}></meshStandardMaterial>
        </mesh>
        </>
    ;
}
function Ring() {
    const matRef = useRef();
    let time = 0;
    useFrame(() => {
        if(matRef.current) {
            time += 0.01;
            matRef.current.uniforms.time.value = time ;
        }
    })
    const uniforms = useMemo(() => ({
        time: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        sky: { type:"t", value: new THREE.TextureLoader().load("/models/psy6.png")},
        uvRate1: {
            value: new THREE.Vector2(1, 1)
        }
    }), []);

    return <mesh>
        <cylinderBufferGeometry  attach="geometry" args={[16,16, 4, 64,1, true]}></cylinderBufferGeometry>
        <shaderMaterial color={"yellow"} ref={matRef} attach="material" vertexColors={true} color={"0xFFFFFF"}  side={THREE.DoubleSide} uniforms={uniforms} vertexShader={`
uniform float time;
varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.141592653589793238;
void main() {
  vUv = uv;
  vNormal = normal;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`}  fragmentShader={`
uniform float time;
uniform float progress;
uniform sampler2D texture1;
uniform vec4 resolution;
uniform sampler2D sky;
varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
float PI = 3.141592653589793238;

vec3 getColor (float amount) {
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

vec3 getColorAmount (vec3 p) {
    float amount = clamp((1.5 - length(p)) / 2.0 ,0.0,1.0);
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

${cnoise}

void main(){
 //vec2 newUV = vUv - vec2(0.5);//(vUv - vec2(0.5))*resolution.zw + vec2(0.5);


    float diff = dot(vec3(1.), vNormal);
    vec3 colors = getColor(vNormal.y);
    //gl_FragColor = vec4(vUv,0.0,1.);
    //gl_FragColor = vec4(vNormal,0.0,1.);
    vec4 txt = texture2D(sky, vUv + 0.2 * cnoise(vec4(vUv * 20., time / 5., 0.), vec4(5.)));
    //gl_FragColor = vec4(vec3(sin(time) * 100. * colors.x ,colors.y  * 100. * cos(time), colors.z * 1000. * tan( time)),1.);
    //gl_FragColor = vec4(txt.x  * cos(time) * 10., txt.y * sin(time),txt.z * tan(time), 1.0);
    //gl_FragColor = vec4(vNormal,1.);
    gl_FragColor = txt;
    gl_FragColor.g += 0.3;
    gl_FragColor.r += 0.3;
}
`}/>
    </mesh>
}

/*

* */
function Sphere({ url }) {

    const meshRef = useRef();
    const materialRef = useRef();
    const uniforms = useMemo(() => ({
        time: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        sky : {type:"t", value: new THREE.TextureLoader().load(url)},
        uvRate1: {
            value: new THREE.Vector2(1, 1)
        }
    }), []);
    const {camera} = useThree();
    let time = 0.0;
    useFrame(() => {
        time += 0.1;
        // console.log(meshRef);
        //ballerinaRef.current.rotation.y += 0.01;
        if(materialRef.current) {

            materialRef.current.uniforms.time.value = time;

        }

    });

    return <mesh>

        <sphereBufferGeometry attach="geometry" args={[1, 32,32]} />
        <shaderMaterial attach="material" vertexColors={true} ref={materialRef} side={THREE.DoubleSide} deepWrite={false}   uniforms={uniforms} vertexShader={`
uniform float time;
varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.141592653589793238;
void main() {
  vUv = uv;
  vNormal = normal;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`}  fragmentShader={`
uniform float time;
uniform float progress;
uniform sampler2D sky;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
float PI = 3.141592653589793238;

float sphere(vec3 p){
    return length(p)-0.5;
    
}

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
mat4 m = rotationMatrix(axis, angle);
return (m * vec4(v, 1.0)).xyz;
}

float sdBox(vec3 p, vec3 b) {
    vec3 q = abs(p) - b;
    return length(max(q,0.0)) + min(max(q.x,max(q.y,q.z)),0.0);
}

float sineCrazy(vec3 p) {
    return 1.0 - (sin(p.x) + sin(p.y) + sin(p.z)) / 5.;
}
float scene(vec3 p){
    //return sphere(p);
    
    vec3 p1 = rotate(p,vec3(1.,1.,1.),time/12.);
    float box = sdBox(p1,vec3(0.5,0.5,0.5));
    
    float scale = 30.0  + 20.0 * sin(time / 6.);
    return max( sphere(p), (0.85 - sineCrazy(p1 * scale)) / scale);
}

vec3 getNormal(vec3 p){
    vec2 o = vec2(0.001,0.);
    
    return normalize(
        vec3(
            scene(p + p.xyy) -  scene(p - p.xyy),
            scene(p + p.yxy) -  scene(p - p.yxy),
            scene(p + p.yyx) -  scene(p - p.yyx)
        )
    );
}

vec3 getColor (float amount) {
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

vec3 getColorAmount (vec3 p) {
    float amount = clamp((1.5 - length(p)) / 2.0 ,0.0,1.0);
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

float f(vec3 p) 
{ 
    p.z-=time*.5;
    return length(cos(p)-.1*cos(9.*(p.z+.1*p.x-p.y)+time*2.0))-(0.9+sin(time)*.1); 
}

${cnoise}
    
void main(){
 //vec2 newUV = vUv - vec2(0.5);//(vUv - vec2(0.5))*resolution.zw + vec2(0.5);
 

 //vec3 color = vec3(0.);


  //float strength = distance(vPosition, vec2(0.5));
     //strength *= 2.0;
  //   strength = 1.0 - strength;

   //vec4 txt = texture2D(sky, vUv);
    vec4 txt = texture2D(sky, vUv + 0.2 * cnoise(vec4(vUv * 20., time / 5., 0.), vec4(5.)));
 //gl_FragColor = vec4(1.0);
    float diff = dot(vec3(1.), vNormal);
    vec3 colors = getColor(vNormal.y);
    
    //gl_FragColor = vec4(vec3(sin(time) * 100. * colors.x ,colors.y  * 100. * cos(time), colors.z * 1000. * tan( time)),1.);
    //gl_FragColor = vec4(vec3(sin(vNormal.x * time), sin(vNormal.y * time ), sin(vNormal.x * time)), 1.);
    gl_FragColor = txt;
}
`}/>
    </mesh>
}

function SpinningThing() {

    return (
        <Suspense fallback={null}>

        </Suspense>
    )
}


function AssetMaterial({ url }) {
    const gltf = useLoader(GLTFLoader, url);
    const uniforms = useMemo(() => ({
        time: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        sky: {type: "t", value: new THREE.TextureLoader().load('/models/psy6.png')},
        uvRate1: {
            value: new THREE.Vector2(1, 1)
        }
    }), []);
    const meshRef = useRef();
    const materialRef = useRef();
    const {camera} = useThree();
    let time = 0.0;
    useFrame(() => {
        time += 0.1;
       // console.log(meshRef);
        //ballerinaRef.current.rotation.y += 0.01;

        meshRef.current.traverse(o => {
            if(o.isMesh) {
                o.material = materialRef.current;
            }
        });
        if(materialRef.current) {

            materialRef.current.uniforms.time.value = time;

        }

    });

    return <group>
        <primitive object={gltf.scene} dispose={null} ref={meshRef}/>
        <shaderMaterial attach="material" vertexColors={true} ref={materialRef} side={THREE.DoubleSide} deepWrite={false}   uniforms={uniforms} vertexShader={`
uniform float time;
varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.141592653589793238;
void main() {
  vUv = uv;
  vNormal = normal;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`}  fragmentShader={`
uniform float time;
uniform float progress;
uniform sampler2D texture1;
uniform vec4 resolution;
uniform sampler2D sky;
varying vec2 vUv;
varying vec3 vNormal;
varying vec3 vPosition;
float PI = 3.141592653589793238;


float sphere(vec3 p){
    return length(p)-0.5;
    
}

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
mat4 m = rotationMatrix(axis, angle);
return (m * vec4(v, 1.0)).xyz;
}

float sdBox(vec3 p, vec3 b) {
    vec3 q = abs(p) - b;
    return length(max(q,0.0)) + min(max(q.x,max(q.y,q.z)),0.0);
}

float sineCrazy(vec3 p) {
    return 1.0 - (sin(p.x) + sin(p.y) + sin(p.z)) / 5.;
}
float scene(vec3 p){
    //return sphere(p);
    
    vec3 p1 = rotate(p,vec3(1.,1.,1.),time/12.);
    float box = sdBox(p1,vec3(0.5,0.5,0.5));
    
    float scale = 30.0  + 20.0 * sin(time / 6.);
    return max( sphere(p), (0.85 - sineCrazy(p1 * scale)) / scale);
}

vec3 getNormal(vec3 p){
    vec2 o = vec2(0.001,0.);
    
    return normalize(
        vec3(
            scene(p + p.xyy) -  scene(p - p.xyy),
            scene(p + p.yxy) -  scene(p - p.yxy),
            scene(p + p.yyx) -  scene(p - p.yyx)
        )
    );
}

vec3 getColor (float amount) {
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

vec3 getColorAmount (vec3 p) {
    float amount = clamp((1.5 - length(p)) / 2.0 ,0.0,1.0);
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

float f(vec3 p) 
{ 
    p.z-=time*.5;
    return length(cos(p)-.1*cos(9.*(p.z+.1*p.x-p.y)+time*2.0))-(0.9+sin(time)*.1); 
}
    
${cnoise}    

void main(){
 //vec2 newUV = vUv - vec2(0.5);//(vUv - vec2(0.5))*resolution.zw + vec2(0.5);
 
 vec4 txt = texture2D(sky, vUv + 0.2 * cnoise(vec4(vUv * 20., time / 5., 0.), vec4(5.)));

 //vec3 color = vec3(0.);


  //float strength = distance(vPosition, vec2(0.5));
     //strength *= 2.0;
  //   strength = 1.0 - strength;

 
 //gl_FragColor = vec4(1.0);
    float diff = dot(vec3(1.), vNormal);
    vec3 colors = getColor(vNormal.y);
    
    gl_FragColor = vec4(vec3(sin(time) * 100. * colors.x + txt.x  ,colors.y  * 100. * cos(time) + txt.y, colors.z * 100. * tan( time) + txt.z),1.);
    //gl_FragColor = vec4(abs(sin(diff*10.0)));

}
`}/>
    </group>
}

const TheScene = ({next, }) => {
    const sceneRef = useRef();
    const faceRef = useRef();
    const modelRef = useRef();
    const {camera} = useThree();
    useFrame(() => {
        //ballerinaRef.current.rotation.y += 0.01;
        camera.lookAt(sceneRef.current.position);
        faceRef.current.rotation.y += 0.01;

      //setX(x => x+0.001);
       // console.log(x);
    })

    return  <scene>

        <group position={[0,0,0]}>


            <group position={[0, 0, 0]} ref={sceneRef}>

                <pointLight color={'white'} position={[2,2,2]}/>
                <group position={[6,1,0]}>
                    <Sphere url={'/models/psy3.png'} />
                </group>

                <group position={[-4,6,3]} scale={[3,3,3]}>
                    <Sphere  url={'/models/psy4.png'}/>
                </group>

                <group position={[-7,2,4]} scale={[0.7,0.7,0.7]}>
                    <Sphere  url={'/models/psy5.png'}/>
                </group>

                <group position={[-2,-3,-4]} scale={[1.5,1.5,1.5]}>
                    <Sphere  url={'/models/psy7.jpg'}/>
                </group>




                <group rotation={[0,0,15]}>
                    <Ring/>
                </group>
                <group position={[0,0,0]} rotation={[0,0,-15]}   >
                    <Ring/>
                </group>

                <group position={[0,0,0]} scale={[19,19,19]} rotation={[1.544,0.6,0]}  >
                    <Sphere  url={'/models/psy2.png'}/>
                </group>
                <group ref={faceRef} position={[0,0,0]}>
                    <group assetRef={modelRef}>
                        <Suspense fallback={null} >
                            <AssetMaterial url={'/models/prismaFace.glb'} />
                        </Suspense>
                    </group>

                    <group position={[0,0,-4]}>
                        <Portal position={new THREE.Vector3(0,0,0)}/>
                    </group>
                    <group position={[0,0,4]} rotation={[Math.PI*3,0,Math.PI]}>
                        <Portal position={new THREE.Vector3(0,0,0)}/>
                    </group>

                    <group position={[4,0,0]} rotation={[0,-Math.PI/2,0]}>
                        <Portal position={new THREE.Vector3(0,0,0)}/>
                    </group>
                    <group position={[-4,0,0]} rotation={[0,Math.PI/2,0]}>
                        <Portal position={ new THREE.Vector3(0,0,0)}/>

                    </group>

                </group>



            </group>
        </group>

    </scene>
}



function GalaxieRenderer() {
    const objRef = useRef();
    const mat = useRef();
    const {gl,camera} = useThree();
    let time  = 0;
    useFrame((state) => {
        if(objRef.current) {

            objRef.current.lookAt(camera.position);
            time +=0.05;
            objRef.current.material.uniforms.time.value = time;
        }


    });



    return <>
        <mesh ref={objRef} position={[0,0,0]} scale={[1,1,1]}>

            <planeBufferGeometry attach="geometry" args={[5, 5,1,1]} />

            <shaderMaterial attach="material" vertexColors={true} ref={mat} side={THREE.DoubleSide} transparent={true} deepWrite={false}  blending={THREE.AdditiveBlending} uniforms={{
                time: { type: "f", value: 0 },
                resolution: { type: "v4", value: new THREE.Vector4() },
                uvRate1: {
                    value: new THREE.Vector2(1, 1)
                }
            }} vertexShader={`
uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.141592653589793238;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`}  fragmentShader={`
uniform float time;
uniform float progress;
uniform sampler2D texture1;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
float PI = 3.141592653589793238;

float sphere(vec3 p){
    return length(p)-0.5;
    
}

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
mat4 m = rotationMatrix(axis, angle);
return (m * vec4(v, 1.0)).xyz;
}

float sdBox(vec3 p, vec3 b) {
    vec3 q = abs(p) - b;
    return length(max(q,0.0)) + min(max(q.x,max(q.y,q.z)),0.0);
}

float sineCrazy(vec3 p) {
    return 1.0 - (sin(p.x) + sin(p.y) + sin(p.z)) / 5.;
}
float scene(vec3 p){
    //return sphere(p);
    
    vec3 p1 = rotate(p,vec3(1.,1.,1.),time/12.);
    float box = sdBox(p1,vec3(0.5,0.5,0.5));
    
    float scale = 30.0  + 20.0 * sin(time / 6.);
    return max( sphere(p), (0.85 - sineCrazy(p1 * scale)) / scale);
}

vec3 getNormal(vec3 p){
    vec2 o = vec2(0.001,0.);
    
    return normalize(
        vec3(
            scene(p + p.xyy) -  scene(p - p.xyy),
            scene(p + p.yxy) -  scene(p - p.yxy),
            scene(p + p.yyx) -  scene(p - p.yyx)
        )
    );
}

vec3 getColor (float amount) {
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

vec3 getColorAmount (vec3 p) {
    float amount = clamp((1.5 - length(p)) / 2.0 ,0.0,1.0);
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}
    
void main(){
 vec2 newUV = vUv - vec2(0.5);//(vUv - vec2(0.5))*resolution.zw + vec2(0.5);
 

 vec3 color = vec3(0.);


 

 gl_FragColor = vec4(color,1.);
 

}
`}/>
            <meshBasicMaterial />
        </mesh>
    </>;
}
function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />

    </effectComposer>;
}

// <afterimagePass attachArray="passes" />
//         <glitchPass attachArray="passes" renderToScreen />
function HigherDimension(prop) {

    return <div>
        <Controls.Provider>
        <Controls.Canvas colorManagement shadowMap camera={{ position: [0, 0, -10], fov: 60 }} style={{ height: "100vh", width: "100vw", background: "black"}}>

            <TheScene next={()=>{}}/>
            <OControls/>
            <PostProcessing/>

        </Controls.Canvas>
        </Controls.Provider>


        <DownCanva onClick={() => {
            window.location = '/';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>1E-1</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>

    </div>
}

export default HigherDimension;
