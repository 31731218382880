import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { animated, useSpring } from 'react-spring-three'
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
//import {useTexture} from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import  ControlsUser from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import { Controls, useControl } from 'react-three-gui';
import Like from "../../components/Like/Like";
import * as THREE from 'three'
//import ChatBot from "aws-amplify-react/src/Interactions";
extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom:200px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;


const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const MobileBtn = styled.div`
  position: fixed;
  bottom: 130px;
  right: 130px;
  width: 30px;
  height: 30px;
  content: "Click";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;



const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);

    // Here's the animation part
    // *************************
    let mixer;
    if (gltf.animations.length) {
        mixer = new THREE.AnimationMixer(gltf.scene);
        const clip = gltf.animations[0];
        const action = mixer.clipAction(clip);
        action.play();
    }

    useFrame((state, delta) => {
        mixer?.update(delta)
    });
    //const texture = useTexture('/img/hearth.png');
   const mesh = useRef();


    return <primitive  ref={mesh} onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

function Rotating ({url, fallback = null, onClick = () => {}}) {
    const mesh = useRef();

    // Rotate mesh every frame, this is outside of React without overhead
   // useFrame(() => (mesh.current.rotation.y += 0.005));

    return <group ref={mesh}>

        <group rotation={[0,MathUtils.degToRad(-120),0]}>

            <group scale={[0.25, 0.25, 0.25]}>
            <Suspense fallback={fallback}>
                <Asset url={url} onClick={onClick}/>
            </Suspense>
            </group>
        </group>


    </group>
}

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({next, insert}) => {
    const { camera }= useThree();
    const [phase , setPhase] = React.useState(false);
    const [value, set] = React.useState(0);
    const { intensity , ...props} = useSpring({intensity: phase ?  1.2 : 0, config:{ duration: 250 , mass: 5}});
    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);
    useEffect(() => {
        const e = setInterval(() => setPhase(i => !i), 1500);

        return () => clearInterval(e);
    });

    //useFrame(() => (mesh.current.rotation.y += 0.005));

    return  <scene>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={6.82} intensity={6.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={6} intensity={4.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={7} intensity={4.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={5.4} intensity={2.6}/>

        <group position={[0,-4,0]} scale={[3,3,3]} rotation={[MathUtils.degToRad(0),MathUtils.degToRad(-50),MathUtils.degToRad(0)]}>
            <Rotating onClick={next} url="/models/floatingflower.glb" fallback={cube}/>
        </group>

        <pointLight position={[-5, 3, -0]} color={0xFFF} distance={4.4} intensity={2.6}/>
        <group>
            <animated.ambientLight intensity={intensity}/>
        </group>

        <ControlsUser />
    </scene>
}

function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function Floating(prop) {
    const [counter, setCounter] = React.useState(0);
    const [insert, setInsert] = React.useState(0);
    return <div>

            <Canvas  style={{ height: "100vh", width: "100vw", background: "black"}}>
                <group position={[-1,0,0]}>
                <TheScene insert={insert}/>
                </group>
                <PostProcessing/>


            </Canvas>



        <DownCanva  onClick={() => {
            window.location = '/oracle';
        }}>
            KAPPAxBETA 4 LIFE
            <Notification >!</Notification>
            <Next>Next >></Next>
        </DownCanva>



        <Version>V7.7.L</Version>
        <Like/>

    </div>
}

export default Floating;
