import React, {useRef, Suspense, useMemo} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader} from "react-three-fiber";
import Controls from "../../components/Controls";
import styled from "styled-components";
import {VRCanvas, DefaultXRControllers,Hands, useXR, useXREvent} from "@react-three/xr";


const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;


const Cube = ({rot = 0.005, sX = 0, axis = "x",...props}) => {
    const { clock} = useThree();
    const material = useRef();

    let did = false;
    const ref = useRef();
    useFrame(() => {
        if(!did) {
            did = true;

            if(axis === "x")
            {
                ref.current.rotation.x = sX;
            }
            else
            {
                ref.current.rotation.y = sX;
            }
        }
        if(axis === "x")
        {
            ref.current.rotation.x += rot;
        }
        else
        {
            ref.current.rotation.y += rot;
        }
        material.current.time = clock.elapsedTime;

    });
    return <mesh ref={ref} {...props}>
        <boxBufferGeometry attach="geometry" args={[0.5,3.5,0.1]} />
        <colorMat
            ref={material}
            attach="material"
        />

    </mesh>;
};

const Star = ({sX = 0, rotation, props}) => {
    return <group rotation={rotation} {...props}>
        <Cube sX={-sX}  position={[0,2,0]}  />
        <Cube  sX={sX}  position={[0,-2,0]} rot={-0.005}/>
        <Cube  sX={sX}  axis={"y"} rotation={
            [0,0, THREE.MathUtils.degToRad(-90)]
        } position={[2, 0,0]}  rot={-0.005}/>
        <Cube  sX={-sX}  axis={"y"} rotation={
            [0,0, THREE.MathUtils.degToRad(-90)]
        } position={[-2,0,0]} />


    </group>;
};

const Scene = () => {
    const { isPresenting, player } = useXR();


    const { camera, gl } = useThree();
    const ref = useRef();
    useFrame(() => {
        ref.current.rotation.z += 0.001;

    });

    React.useEffect(() => {
        if(gl.xr.isPresenting) {
            ref.current.position.z = -10
        } else {
            ref.current.position.z = 0
        }
    }, [gl.xr.isPresenting]);

    return   <group ref={ref}>
        <Star

        />

        <Star
            rotation={[0,0,THREE.MathUtils.degToRad(-30)]}
            sX={ THREE.MathUtils.degToRad(45)}
        />

        <Star
            rotation={[0,0,THREE.MathUtils.degToRad(-60)]}
            sX={ THREE.MathUtils.degToRad(80)}
        />
    </group>;
};

const Cameleon = (prop) => {

    return <div>
        <VRCanvas orthographic camera={{zoom: 120}} style={{ height: "100vh", width: "100vw", background: "black"}}>
            <scene>
                <ambientLight position={[0, 0, 2]}/>
            <Scene/>
            <Controls/>
                <DefaultXRControllers />
            </scene>
        </VRCanvas>
        <DownCanva onClick={() => {
            window.location = '/stage';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>3</Notification>
            <Next>Next >></Next>
        </DownCanva>
    </div>;
};

export default Cameleon;
