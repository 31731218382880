import React from "react";
import {Canvas} from "react-three-fiber";
import MusicShader from "./MusicShader";
import {AudioAnalyser} from "react-three-fiber/components";

function SoCool () {
    var fftSize = 128;

    return <div></div>;
}

function Audio(prop) {
    const [start, setStart] = React.useState(false);

    return <div>
        <Canvas>
            <scene>
                <SoCool/>
            </scene>
        </Canvas>

    </div>
}
/*
*  <Canvas vr={true}  onCreated={({ gl }) => document.body.appendChild(VRButton.createButton(gl))} style={{ height: "100vh", width: "100vw", background: "black"}}>

        </Canvas>
* */

export default Audio;
