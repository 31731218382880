import React, {useRef, Suspense, useMemo} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader} from "react-three-fiber";
import Controls from "../../components/Controls";
import {TextureLoader} from "three";
import me from "./me.png";
const Cube = () => {
    const ref = useRef();
    useFrame(() => (ref.current.rotation.y += 0.005));


    return <mesh ref={ref}>
        <boxBufferGeometry attach="geometry" args={[5,1,5]} />
        <meshStandardMaterial  attach="material" color={"0xFFF"}></meshStandardMaterial>
    </mesh>;
};

const Cube2 = () => {
    const ref = useRef();
    useFrame(() => (ref.current.rotation.y += 0.005));


    return <mesh ref={ref} position={[0,-2,0]}>
        <boxBufferGeometry attach="geometry" args={[1,1,1]} />
        <meshStandardMaterial  attach="material" color={"0xFFF"}></meshStandardMaterial>
    </mesh>;
};


const CubeTex = (props) => {
    const ref = useRef();
    useFrame(() => (ref.current.rotation.y += 0.005));
    const texture = useMemo(() => new TextureLoader().load(me), []);

    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture.repeat.set( 5, 1 );


    var materials = [

        new THREE.MeshPhongMaterial( { map: texture } ),
        new THREE.MeshPhongMaterial( { map: texture,  color: "violet"  } ),
        new THREE.MeshPhongMaterial( { color: 0x000, transparent: true } ),
        new THREE.MeshPhongMaterial( {  color: 0x000,  transparent: true } ),
        new THREE.MeshPhongMaterial( { map: texture, color: "yellow" } ),
        new THREE.MeshPhongMaterial( { map: texture , color: 0xb1c9b4  } )
    ];

    return <mesh ref={ref} position={[0,0,0]} material={materials}  {...props}>
        <boxBufferGeometry attach="geometry" args={[5,1,5]} />

    </mesh>;
};

/*
 <meshLambertMaterial attach="material" side={THREE.DoubleSide} transparent={true} >
            <primitive attach="map" object={texture} />
        </meshLambertMaterial>*/
const Cameleon = (prop) => {
return <div>
    <Canvas orthographic camera={{zoom: 120}} style={{ height: "100vh", width: "100vw", background: "black"}}>
      <scene>
          <pointLight position={[0, 0, 2]}/>
          <ambientLight position={[0, 0, 2]}/>
          <CubeTex />
          <CubeTex position={[0,-2,0]}/>

          <Controls/>
      </scene>
    </Canvas>
</div>;
};

export default Cameleon;
