import React, { Suspense,  useCallback, useEffect, useRef, useMemo, useState} from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;



const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;


const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

function createAudioMeter(audioContext,clipLevel,averaging,clipLag) {
    var processor = audioContext.createScriptProcessor(512);
    processor.onaudioprocess = volumeAudioProcess;
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = clipLevel || 0.98;
    processor.averaging = averaging || 0.95;
    processor.clipLag = clipLag || 750;

    // this will have no effect, since we don't copy the input to the output,
    // but works around a current Chrome bug.
    processor.connect(audioContext.destination);

    processor.checkClipping =
        function(){
            if (!this.clipping)
                return false;
            if ((this.lastClip + this.clipLag) < window.performance.now())
                this.clipping = false;
            return this.clipping;
        };

    processor.shutdown =
        function(){
            this.disconnect();
            this.onaudioprocess = null;
        };

    return processor;
}

function volumeAudioProcess( event ) {
    var buf = event.inputBuffer.getChannelData(0);
    var bufLength = buf.length;
    var sum = 0;
    var x;

    // Do a root-mean-square on the samples: sum up the squares...
    for (var i=0; i<bufLength; i++) {
        x = buf[i];
        if (Math.abs(x)>=this.clipLevel) {
            this.clipping = true;
            this.lastClip = window.performance.now();
        }
        sum += x * x;
    }

    // ... then take the square root of the sum.
    var rms =  Math.sqrt(sum / bufLength);

    // Now smooth this out with the averaging factor applied
    // to the previous sample - take the max here because we
    // want "fast attack, slow release."
    this.volume = Math.max(rms, this.volume*this.averaging);
}

function AssetAni({ url }) {
    const gltf = useLoader(GLTFLoader, url);
    const ref = useRef();


    /*useEffect(() => {


        var position = Object.assign({}, spine.position);
        var ehs = true;

    },[]);*/


    useFrame(state => {
        const spine = gltf.scene.getObjectByName("BodySpine3");
        spine.position.y = (Math.sin(state.clock.getElapsedTime()) * 100000) / 15 + 4
    });

    return <primitive ref={ref}  object={gltf.scene} dispose={() => {console.log(ref)}} >

    </primitive>
}

function AssetAniMouth({ mouth, url }) {
    const gltf = useLoader(GLTFLoader, url);
    const ref = useRef();
    const [active, setActive] = React.useState(false);
    var position = null;


    useFrame(state => {
        const spine = gltf.scene.getObjectByName("BodySpine3");
        if(!position) {
            position = Object.assign({}, spine.position);
            console.log(position);
        }

        spine.position.y = 4.16 + mouth * 2;
       // spine.position.y = (Math.sin(state.clock.getElapsedTime()) * 10) / 15 + 4
    });

    return <primitive ref={ref} onClick={e => setActive(!active)}

     object={gltf.scene} dispose={() => {console.log(ref)}} >

    </primitive>
}

function MicroPhone({url, onClick}) {
    const [volume, setVolume] = useState(0);

    var audioContext = null;
    var meter = null;

    function didntGetStream() {
        alert('Stream generation failed.');
    }

    var mediaStreamSource = null;

    const gotStream = (stream) => {
        // Create an AudioNode from the stream.
        mediaStreamSource = audioContext.createMediaStreamSource(stream);

        // Create a new volume meter and connect it.
        meter = createAudioMeter(audioContext);
        mediaStreamSource.connect(meter);

    }

    useEffect(() => {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;

        // grab an audio context
        audioContext = new AudioContext();
        // Attempt to get audio input
        try {
            // monkeypatch getUserMedia
            navigator.getUserMedia =
                navigator.getUserMedia ||
                navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia;


            // ask for an audio input
            navigator.getUserMedia(
                {
                    "audio": {
                        "mandatory": {
                            "googEchoCancellation": "true",
                            "googAutoGainControl": "ture",
                            "googNoiseSuppression": "ture",
                            "googHighpassFilter": "ture"
                        },
                        "optional": []
                    },
                }, gotStream, didntGetStream);
            setInterval(() => {
                if(meter) {
                    meter.checkClipping();
                    console.log(meter.volume * 100);
                    setVolume(meter.volume * 10)
                }
            }, 100)
        } catch (e) {
            alert('getUserMedia threw exception :' + e);
        }
    },[]);


    return <>
        <AssetAniMouth mouth={volume} url={url} onClick={onClick}/>
    </>;
}
function Rotating ({url, fallback = null, onClick = () => {}}) {
    const mesh = useRef();
    const chameleon = useRef();

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.y += 0.005));

    return <group ref={mesh}>
        <group rotation={[MathUtils.degToRad(-90),0,0]}>
            <Plane size={10}/>
        </group>

        <group rotation={[0,MathUtils.degToRad(-120),0]}>
            <group scale={[0.25, 0.25, 0.25]}>
                <Suspense fallback={fallback}>
                    <MicroPhone url={url} onClick={() => {}}/>

                </Suspense>
            </group>
        </group>


    </group>
}


const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({next, }) => {

    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);

    return  <scene>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>

        <group position={[0,-2.5,0]}>
            <Rotating onClick={next} url="/models/kappa2d.glb" fallback={cube}/>
        </group>
        <Controls />
    </scene>
}

function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function David(prop) {
    const [counter, setCounter] = React.useState(0);

//    <PostProcessing/>

    return <div>
        <Canvas style={{ height: "100vh", width: "100vw", background: "black"}}>
            <TheScene />

        </Canvas>


        <DownCanva>
            KAPPAxBETA
            <Notification onClick={() => {}}>3</Notification>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>

    </div>
}

export default David;
