import React from "react";
import "./App.css";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Landing from "../pages/Landing/Landing";
import David from "../pages/David/David";
import Frame from "../pages/Frame/VR";
import AR from "../pages/AR/AR";
import Schutz from "../pages/schutz/schutz";
import Audio from "../pages/Audio/Audio";
import Deform from "../pages/Deform/Deform";
import Room from "../pages/Room/Room";
import Chameleon from "../pages/chameleon/Chameleon";
import Cubes from "../pages/Cubes/Cubes";
import GoodKappa from "../pages/GoodKappa/GoodKappa";
import GoodKappa2 from "../pages/GoodKappa2/GoodKappa";
import Organic from "../pages/organic";
import Show from "../pages/Show/Show";
import Stage from "../pages/Stage/Stage";
import Medusa from "../pages/Medusa/Medusa";
import Robert from "../pages/Robert/Robert";
import Cassandra from "../pages/Cassandra/Cassandra";
import Oracle from "../pages/Oracle/Oracle";
import GOF from "../pages/GOF/GOF";
import Marble from "../pages/Marble/Marble";
import Me from "../pages/Me/Me";
import Galaxy from "../pages/galaxy/Galaxy";
import Ballarina from "../pages/Balllarina/Ballarina";
import Atomic from "../pages/Atomic/Atomic";
import Hand from "../pages/Hand/Hand";
import HigherDimension from "../pages/HigherDimension/HigherDimension";
import Floating from "../pages/Floating/Floating";
import Cutecube from "../pages/Cutecube/Cutecube";
import World from "../pages/LittlePrince/World";

import CubeRoom from "../pages/CubesRoom/CubesRoom";
import ArthouseLanding from "../pages/Arthouse/Landing";
import ArthouseSecondPage from "../pages/Arthouse/SecondPage";
import ArthouseThirdPage from "../pages/Arthouse/ThirdPage";
import ArthouseFourthPage from "../pages/Arthouse/FourthPage";

import OutOfControl from "../pages/OutOfControl/OutOfControl";

function App() {
  return (
    <BrowserRouter>
      <Switch>
          <Route path="/arthouse" exact component={ArthouseLanding} />
          <Route path="/arthouse/second" exact component={ArthouseSecondPage}/>
          <Route path="/arthouse/third" exact component={ArthouseThirdPage}/>
          <Route path="/arthouse/fourth" exact component={ArthouseFourthPage}/>
          <Route path="/outOfControl" exact component={OutOfControl}/>
          <Route path="/david" exact component={David} />
          <Route path="/medusa" exact component={Medusa} />
          <Route path="/robert" exact component={Robert} />
          <Route path="/stage" exact component={Stage} />
          <Route path="/show" exact component={Show} />
          <Route path="/goodkappa" exact component={GoodKappa} />
          <Route path="/goodkappa2" exact component={GoodKappa2} />
          <Route path="/schutz" exact component={Schutz} />
          <Route path="/frame" exact component={Frame} />
          <Route path="/ar" exact component={AR} />
          <Route path="/audio" exact component={Audio} />
          <Route path="/deform" exact component={Deform} />
          <Route path="/chameleon" exact component={Chameleon} />
          <Route path="/room" exact component={Room} />
          <Route path="/cubes" exact component={Cubes} />
          <Route path="/organic" exact component={Organic} />
          <Route path="/cassandra" exact component={Cassandra} />
          <Route path="/oracle" exact component={Oracle} />
          <Route path="/cuberoom" exact component={CubeRoom} />
          <Route path="/gof" exact component={GOF} />
          <Route path="/marble" exact component={Marble} />
          <Route path="/me" exact component={Me} />
          <Route path="/galaxy" exact component={Galaxy} />
          <Route path="/ballarina" exact component={Ballarina} />
          <Route path="/atomic" exact component={Atomic} />
          <Route path="/hand" exact component={Hand} />
          <Route path="/higherDimension" exact component={HigherDimension} />
          <Route path="/floating" exact component={Floating} />
          <Route path="/cutecube" exact component={Cutecube} />
          <Route path="/littleprince" exact component={World} />
          <Route  path="/" component={Landing} />

      </Switch>
    </BrowserRouter>
  );
}

export default App;
