import React, {useState, useEffect,useRef,useMemo} from "react";
import styled, {css} from "styled-components";
import img from "./artpiece.jpg"
import {NavLink} from "react-router-dom";
import {Grommet, Box, Main, Image, Text, Grid, List} from 'grommet'
import { useSpring, config } from "react-spring"
import {animated} from "react-spring";

const theme = {
    "global": {
        "colors": {
            "background": {
                "light": "#ffffff",
                "dark": "#000000"
            }
        },
        "font": {
            "family": "-apple-system,\n         BlinkMacSystemFont, \n         \"Segoe UI\", \n         Roboto, \n         Oxygen, \n         Ubuntu, \n         Cantarell, \n         \"Fira Sans\", \n         \"Droid Sans\",  \n         \"Helvetica Neue\", \n         Arial, sans-serif,  \n         \"Apple Color Emoji\", \n         \"Segoe UI Emoji\", \n         \"Segoe UI Symbol\""
        }
    },
    "button": {
        "extend": [
            null
        ]
    }
}

const data = [
    {
        who:"me",
        id:"34erfkdoe",
        type: "ghost",
        text: ""
    },
    {
        who:"me",
        id:"dfregv",
        text: "How much is this worth, when I can ask kindly 🙈"
    },
    {
        who: "thestripgallery",
        id: "ekivmfigmrobdf",
        text: "We can sell this original Prefab77 artwork for 890 Eur (5% discount if You subscribe to our newsletter - link in bio). Here the artwork informations:"
    },
    {
        who: "thestripgallery",
        id: "scsfkfjkfjrdf",
        text: "Countdown to violence\n" +
            "\n" +
            "                    Ripped and torn screenprinted newsprint gold,\n" +
            "                    metallic and coloured pattern torn and varnished\n" +
            "                    98×68 cm\n" +
            "                    Certificate of authenticity released by the artist"
    },
    {
        who: "thestripgallery",
        id: "s99ccefsfdf",
        text: "Thank You. The Strip Gallery"
    },
    {
        who:"me",
        id: "scssgtrdf9",
        text: "Okay thank you very much, is there some referral if I find someone who will buy it?"
    }, {
        who:"me",
        id: "scss9gtrdf3",
        text:"Your shop is really cool 😎"
    },
    {
        who: "thestripgallery",
        id: "scssg9tr4df",
        text: "We are an art gallery, we can reserve an exclusive price for more than one purchase. All our artworks for sale are exclusively our property."
    }, {
        who: "thestripgallery",
        id: "scssgtr9d99f",
        text: "Thank you. We can sell directly to you anyway."
    },
    {
        who: "me",
        id: "scssgtw4090f",
        text: "Okay I got it"
    }, {
        who: "me",
        id: "scs987sgtrdf",
        text: "Thanks 🙏"
    },
    {
        who: "thestripgallery",
        id: "ssfr45tr0df",
        text: "You are welcome."
    },
    {
        who: "thestripgallery",
        id: "ssfr45dfg59wvrbzlrdf",
        text: "If you want to proceed with the purchase, we are available. And don't forget to sign up for the newsletter. Then it will be enough for you to tell me which artworks you want to buy, we will indicate the price and accept PayPal and bank transfers. The Strip Gallery"
    },
    {
        who: "me",
        id: "ssfr4dvvzfjztsgbfvsdfyee5trdf",
        text: "I will need some time to see what I really like and also discover the artist behind, no rush please :)"
    },
    {
        who: "me",
        id: "s4tmdrbgdjuzjfz45sghtrdf",
        text: "But good how digital versatile you are with payment"
    },
    {
        who: "me",
        id: "s4tmSFsfthndeRBVMLdr45rtrdf",
        text: "What is with bitcoins?"
    },
    {
        who: "thestripgallery",
        id: "s4tmdr45txxffrTONOf",
        text: "No problem .. when you want we are here at your disposal."
    },
    {

        who: "thestripgallery",
        id: "s4tmdr4SDFFPPDPSDP5tsdfrdf",
        text: "No bitcoin. PayPal or bank transfer.\n" +
            "\n" +
            "                    The Strip Gallery"
    },
    {
        id: "s4tmdr45trdEND",
        who: "me",
        text: "Okay"
    }
];

const useScrollTo = (containerRef, setY) => {
    return (targetElement) => {

        const value =  containerRef.current.scrollHeight - 10;

        setY({
            y: value,
            reset: true,
            from: {y:  containerRef.current.scrollHeight },
            onFrame: props => {
                containerRef.current.scroll(0, props.y)
            }
        });
    }
};

const LogoStyle = styled.div`
    position: fixed;
    top: 10%;
    left: 10%;
    font-size: 2rem;
    border: solid 7px rgba(0,0,0, 0.6);
    padding: 5px;
    font-style: italic;
    color: rgba(0,0,0, 0.6);
`;


const Headline = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
`;

const CallToAction = styled.div`
    position: fixed;
    font-weight: bold;
    font-size: 3rem;
    bottom: 30px;
    display: flex;
    right: 50px;
    background-color: yellow;
    font-family: "PixelMillennium";
    padding: 10px;
    border: double orange 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    > a:active, > a:visited, > a {
      text-decoration: none;
      color: black;
    }
`;

const BubbleContainer = styled.div`
   display: flex;
   max-width: 300px;
   min-width: 300px;
   background-color: mediumblue;
   color: white;
   
   border: deeppink double 3px;
   ${props => props.who !== "me" && css`

    background-color: green!important;
    color: white;
  `}
   
`;
const BubbleWrapper = styled(animated.div)`
   display: flex;
    ${props => props.who !== "me" && css` 
      margin-left: -50px;
  `}
      ${props => props.space && css`
       margin-bottom: 10px
  `}
         ${props => props.visable && css`

       display: none;
  `}
     
    
`;


const ChatBubble = ({who,id, space, setY,visable,containerRef, elementRef, ...props}) => {
    const scrollTo = useScrollTo(containerRef, setY);
    const ref = React.createRef();
    useEffect(() => {
        if(!visable) {
           setTimeout(() => {
               scrollTo(ref);
           }, 400)
        }
    },[visable]);
    return <BubbleWrapper ref={ref} visable={visable} space={space} who={who}><BubbleContainer who={who}><Text margin="xsmall">
                    {props.children}
    </Text></BubbleContainer></BubbleWrapper>


};

const Chat = styled.div`
  display: flex;
  flex-direction: column;
`;

const Group = styled.div`
  margin-bottom: 10px
`;
const ChatContainer = (props) => {
    return <Chat>{props.children}</Chat>
};

const ChatMaschineText = ({data, who, space,setY, up, visable, number, containerRef}) => {
    const elementRef = React.createRef();
    useEffect(() => {
        setTimeout(() => { up() }, (number * 2000) + 100)
    },[])
    if(data.type === "ghost") {
        return <div style={{margin: 20}}></div>
    }
    return <ChatBubble id={data.id} ref={elementRef} elementRef={elementRef} containerRef={containerRef} setY={setY} visable={visable} space={space} who={who} >
        {data.text}
    </ChatBubble>
};

const isSpace = (item, data, index, state) => {
    if(index + 1 >= data.length) return true;
    if(item.who === data[index + 1].who) {
        return false;
    }
    return true;
};

const isVisable = (item, data, index, state) => {
  if(state >= index) {
      return false;
  }
  return true;
};

const ChatMaschine = ({data, state,containerRef,up, ...props}) => {
    const [, setY] = useSpring( () => ({from: { y: 0 }, config: config.wobbly}));
    return <>
        {data.map((i, index) =>
            <ChatMaschineText key={"chatmaschine-group-" + i.id} up={up} containerRef={containerRef} setY={setY} visable={isVisable(i, data, index, state)} space={isSpace(i, data, index, state)} number={index} data={i} who={i.who} state={state} />
        )}
    </>
};
const Landing = (props) => {
    const [state, setStage] = useState(0);
    const view = React.useRef();
    const up = () => {
        setStage(i => i+1);
    };
    return ( <Grommet full theme={theme}>
        <Box ref={view} fill="vertical" overflow="auto" align="center" flex="grow" onClick={up} >
            <ChatContainer>
                <img src={img} width={"300px"} />
               <ChatMaschine up={up} containerRef={view} data={data} state={state}/>
               <div style={{margin: "100px"}}></div>
            </ChatContainer>
        </Box>
        <CallToAction onClick={() => window.location = "/arthouse/fourth"}>Next</CallToAction>

    </Grommet>);
};

/*
 <Group><ChatBubble >How much is this worth, when I can ask kindly 🙈</ChatBubble></Group>
                <Group><ChatBubble who={"thestripgallery"}>We can sell this original Prefab77 artwork for 890 Eur (5% discount if You subscribe to our newsletter - link in bio).
                    Here the artwork informations:</ChatBubble>
                <ChatBubble who={"thestripgallery"}>Countdown to violence

                    Ripped and torn screenprinted newsprint gold,
                    metallic and coloured pattern torn and varnished
                    98×68 cm
                    Certificate of authenticity released by the artist</ChatBubble>
                <ChatBubble who={"thestripgallery"}>Thank You. The Strip Gallery</ChatBubble>
                </Group>
                <Group>
                <ChatBubble>Okay thank you very much, is there some referral if I find someone who will buy it?</ChatBubble>
                <ChatBubble>Your shop is really cool 😎</ChatBubble>
                </Group>
                <Group>
                <ChatBubble who={"thestripgallery"}>We are an art gallery, we can reserve an exclusive price for more than one purchase. All our artworks for sale are exclusively our property.</ChatBubble>
                <ChatBubble who={"thestripgallery"}>Thank you. We can sell directly to you anyway.</ChatBubble>
                </Group>
                <Group>
                    <ChatBubble>Okay I got it</ChatBubble>
                <ChatBubble>Thanks 🙏</ChatBubble>
                </Group>
                <Group>
                <ChatBubble who={"thestripgallery"}>You are welcome.</ChatBubble>
                <ChatBubble who={"thestripgallery"}>If you want to proceed with the purchase, we are available. And don't forget to sign up for the newsletter. Then it will be enough for you to tell me which artworks you want to buy, we will indicate the price and accept PayPal and bank transfers.
                    The Strip Gallery</ChatBubble>
                </Group>
                <Group>
                <ChatBubble>I will need some time to see what I really like and also discover the artist behind, no rush please :)</ChatBubble>
                <ChatBubble>But good how digital versatile you are with payment</ChatBubble>
                <ChatBubble>What is with bitcoins?</ChatBubble>
                </Group>
                <Group>
                <ChatBubble who={"thestripgallery"}>No problem .. when you want we are here at your disposal.</ChatBubble>
                <ChatBubble who={"thestripgallery"} hearth={true}>No bitcoin. PayPal or bank transfer.

                    The Strip Gallery</ChatBubble>
                </Group>
                <ChatBubble  hearth={true}>Okay</ChatBubble>
* */

/*
 <Bg>
        <h1><LogoStyle>κXβ</LogoStyle></h1>
            <CallToAction> <NavLink to={"/David"}>Next ></NavLink></CallToAction>

        <Container>
            <InnerContainer>
            <img src={img}  width="300"/>
            <ChatBubble >How much is this worth, when I can ask kindly 🙈</ChatBubble>
            <ChatBubble who={"thestripgallery"}>We can sell this original Prefab77 artwork for 890 Eur (5% discount if You subscribe to our newsletter - link in bio).
                Here the artwork informations:</ChatBubble>
            <ChatBubble who={"thestripgallery"}>Countdown to violence

                Ripped and torn screenprinted newsprint gold,
                metallic and coloured pattern torn and varnished
                98×68 cm
                Certificate of authenticity released by the artist</ChatBubble>
            <ChatBubble who={"thestripgallery"}>Thank You. The Strip Gallery</ChatBubble>
            <ChatBubble>Okay thank you very much, is there some referral if I find someone who will buy it?</ChatBubble>
            <ChatBubble>Your shop is really cool 😎</ChatBubble>
            <ChatBubble who={"thestripgallery"}>We are an art gallery, we can reserve an exclusive price for more than one purchase. All our artworks for sale are exclusively our property.</ChatBubble>
            <ChatBubble who={"thestripgallery"}>Thank you. We can sell directly to you anyway.</ChatBubble>
            <ChatBubble>Okay I got it</ChatBubble>
            <ChatBubble>Thanks 🙏</ChatBubble>
            <ChatBubble who={"thestripgallery"}>You are welcome.</ChatBubble>
            <ChatBubble who={"thestripgallery"}>If you want to proceed with the purchase, we are available. And don't forget to sign up for the newsletter. Then it will be enough for you to tell me which artworks you want to buy, we will indicate the price and accept PayPal and bank transfers.
                The Strip Gallery</ChatBubble>
            <ChatBubble>I will need some time to see what I really like and also discover the artist behind, no rush please :)</ChatBubble>
            <ChatBubble>But good how digital versatile you are with payment</ChatBubble>
            <ChatBubble>What is with bitcoins?</ChatBubble>
            <ChatBubble who={"thestripgallery"}>No problem .. when you want we are here at your disposal.</ChatBubble>
            <ChatBubble who={"thestripgallery"} hearth={true}>No bitcoin. PayPal or bank transfer.
                The Strip Gallery</ChatBubble>
            <ChatBubble  hearth={true}>Okay</ChatBubble>
            </InnerContainer>
        </Container>

    </Bg>

    const data = [{
    id: "first",
    response: [{
        id:"dfregv",
        text: "How much is this worth, when I can ask kindly 🙈"}
        ]
},
    {
        who: "thestripgallery",
        response: [{
            id: "ekivmfigmrobdf",
            text: "We can sell this original Prefab77 artwork for 890 Eur (5% discount if You subscribe to our newsletter - link in bio). Here the artwork informations:"
        }, {
            id: "scsfkfjkfjrdf",
            text: "Countdown to violence\n" +
                "\n" +
                "                    Ripped and torn screenprinted newsprint gold,\n" +
                "                    metallic and coloured pattern torn and varnished\n" +
                "                    98×68 cm\n" +
                "                    Certificate of authenticity released by the artist"
        },
            {
                id: "s99ccefsfdf",
                text: "Thank You. The Strip Gallery"
            }]
    },
    {
        id: "second",
        response: [{
            id: "scssgtrdf9",
            text: "Okay thank you very much, is there some referral if I find someone who will buy it?"
        }, {
            id: "scss9gtrdf3",
            text:"Your shop is really cool 😎"
        }]
    },
    {
        id: "third",
        who: "thestripgallery",
        response: [{
            id: "scssg9tr4df",
            text: "We are an art gallery, we can reserve an exclusive price for more than one purchase. All our artworks for sale are exclusively our property."
        }, {
            id: "scssgtr9d99f",
            text: "Thank you. We can sell directly to you anyway."
        }]
    },
    {
        id: "fourth",
        response: [{
            id: "scssgtw4090f",
            text: "Okay I got it"
        }, {
            id: "scs987sgtrdf",
            text: "Thanks 🙏"
        }]
    },
    {
        id: "six",
        who: "thestripgallery",
        response: [
            {
                id: "ssfr45tr0df",
                text: "You are welcome."
            },
            {
                id: "ssfr45dfg59wvrbzlrdf",
                text: "If you want to proceed with the purchase, we are available. And don't forget to sign up for the newsletter. Then it will be enough for you to tell me which artworks you want to buy, we will indicate the price and accept PayPal and bank transfers. The Strip Gallery"
            }
        ]
    },
    {
        id: "seven",
        response: [
            {
                id: "ssfr4dvsgbfvsdfyee5trdf",
                text: "I will need some time to see what I really like and also discover the artist behind, no rush please :)"
            },
            {
                id: "s4tmdr45sghtrdf",
                text: "But good how digital versatile you are with payment"
            },
            {
                id: "s4tmSFRBVMLdr45rtrdf",
                text: "What is with bitcoins?"
            }
        ]
    },
    {
        id: "eight",
        who: "thestripgallery",
        heath: true,
        response: [
            {
                id: "s4tmdr45trTONOf",
                text: "No problem .. when you want we are here at your disposal."
            },
            {
                id: "s4tmdr4SDFFPPDPSDP5trdf",
                text: "No bitcoin. PayPal or bank transfer.\n" +
                    "\n" +
                    "                    The Strip Gallery"
            }
        ]
    },
    {
        id: "nine",
        hearth: true,
        response: [
            {
                id: "s4tmdr45trdEND",
                text: "Okay"
            }
        ]
    }
];
* */
export default Landing;
