import React, { Suspense, useRef } from 'react'


import styled, { css } from "styled-components";
import Amplify, { Interactions } from 'aws-amplify';
//import ChatBot from "aws-amplify-react/src/Interactions";
// https://api.countapi.xyz/info/kappa/e9cae974-35e0-4150-9131-2116169d7792

const api = {namespace:"kappa",key:"e9cae974-35e0-4150-9131-2116169d7792",value:42};


const Wrapper = styled.div`

  font-size: 1px;
  width: 60px;
  height: 60px;
  position: fixed;
  top: 10px;
  left: 10px;
   
   ${props => props.pump && css`
    animation: animate 0.7s ease infinite;
`}
`;

const Container = styled.div`
    transform: scale(0.2);
    display: table;
    margin-top: 0;
    transition: margin-top 2s ease-in-out;
`;

const Thanks = styled.div`
 font-family: "PixelMillennium";
 position: absolute;
 color: white;
 height: 30px;
 width: 100px;
 font-size: 20pt;
 text-align: center;
 display: none;
   ${props => props.pump && css`
    display: block;
`}
`;
const Hearth = styled.div`
    background: rgba(0, 0, 0, 0);
    height: 20em;
    width: 20em;
    box-shadow: 
    /*1st line*/
    40em 0    0 #111,
    60em 0    0 #111,
    140em 0    0 #111,
    160em 0    0 #111,
    /*2nd line*/
    20em 20em    0 #111,
    40em 20em    0 #EF3030,
    60em 20em    0 #EF3030,
    80em 20em    0 #111,
    120em 20em    0 #111,
    140em 20em    0 #EF3030,
    160em 20em    0 #EF3030,
    180em 20em    0 #111,
    /*3rd line*/
    0em 40em    0 #111,
    20em 40em    0 #EF3030,
    40em 40em    0 #FFF,
    60em 40em    0 #EF3030,
    80em 40em    0 #EF3030,
    100em 40em    0 #111,
    120em 40em    0 #EF3030,
    140em 40em    0 #EF3030,
    160em 40em    0 #EF3030,
    180em 40em    0 #EF3030,
    200em 40em    0 #111,
    /*4th line*/
    0em 60em    0 #111,
    20em 60em    0 #EF3030,
    40em 60em    0  #EF3030,
    60em 60em    0 #EF3030,
    80em 60em    0 #EF3030,
    100em 60em    0 #EF3030,
    120em 60em    0 #EF3030,
    140em 60em    0 #EF3030,
    160em 60em    0 #EF3030,
    180em 60em    0 #EF3030,
    200em 60em    0 #111,
    /*5th line*/
    0em 80em    0 #111,
    20em 80em    0 #EF3030,
    40em 80em    0 #EF3030,
    60em 80em    0 #EF3030,
    80em 80em    0 #EF3030,
    100em 80em    0 #EF3030,
    120em 80em    0 #EF3030,
    140em 80em    0 #EF3030,
    160em 80em    0 #EF3030,
    180em 80em    0 #EF3030,
    200em 80em    0 #111,
    /*6th line*/
    0em 100em    0 #111,
    20em 100em    0 #EF3030,
    40em 100em    0 #EF3030,
    60em 100em    0 #EF3030,
    80em 100em    0 #EF3030,
    100em 100em    0 #EF3030,
    120em 100em    0 #EF3030,
    140em 100em    0 #EF3030,
    160em 100em    0 #EF3030,
    180em 100em    0 #EF3030,
    200em 100em    0 #111,
    /*7th line*/
    20em 120em    0 #111,
    40em 120em    0 #B71F33,
    60em 120em    0 #EF3030,
    80em 120em    0 #EF3030,
    100em 120em    0 #EF3030,
    120em 120em    0 #EF3030,
    140em 120em    0 #EF3030,
    160em 120em    0 #B71F33,
    180em 120em    0 #111,
    /*8th line*/
    40em 140em    0 #111,
    60em 140em    0 #B71F33,
    80em 140em    0 #EF3030,
    100em 140em    0 #EF3030,
    120em 140em    0 #EF3030,
    140em 140em    0 #B71F33,
    160em 140em    0 #111,
    /*9th line*/
    60em 160em    0 #111,
    80em 160em    0 #B71F33,
    100em 160em    0 #EF3030,
    120em 160em    0 #B71F33,
    140em 160em    0 #111,
    /*10th line*/
    80em 180em    0 #111,
    100em 180em    0 #B71F33,
    120em 180em    0 #111,
    /*11th line*/
    100em 200em    0 #111;
`;

function Like(prop) {
    const [pump, setPump] = React.useState(false);

    return <Wrapper pump={pump} onClick={() => {
        if(!pump) {
            fetch(`https://api.countapi.xyz/hit/${api.namespace}/${api.key}`).then(() => {
                console.log("Hurra :)")
            })
        }
        setPump(true);

    }}>
        <Container >
            <Hearth>
            </Hearth>
        </Container>
        <Thanks pump={pump}>Thanks :)</Thanks>
    </Wrapper>
}

export default Like;
