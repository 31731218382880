import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

//import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
import {useForwardedRef} from "grommet/es6/utils";
import { Controls, useControl } from 'react-three-gui';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";




//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass, OrbitControls});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;

const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;

const facts = [
    "The statue appears to show David after he has decided to fight Goliath, but before the battle has taken place, a moment between conscious choice and action – fight and escape.",
    "The statue of David is a symbol of the City of Florence, Italy, and is Michelangelo's most famous work of sculpture. It is often called The David.",
    "It is made of marble and is 17 feet tall. It is a statue of a shepherd, David, whose story is in the Bible. David fought a battle with a giant soldier called Goliath. He beat Goliath by knocking him down with a small stone from his slingshot. David later became King of Israel."
];

const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick , ref = null}) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive ref={ref} onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

 function OControls() {
    const controls = useRef();
    const { camera, gl } = useThree();
    // update the controls for damping
    useFrame(() => controls.current.update());

    // Enable damping and rotateSpeed to have a more natural feeling
    return (
        <orbitControls
            ref={controls}
            args={[camera, gl.domElement]}
            enableDamping
            dampingFactor={0.1}
            enablePan={false}
            enableZoom={false}
            maxAzimuthAngle={Math.PI / 2.3}
            maxPolarAngle={Math.PI / 2}
            minAzimuthAngle={-Math.PI / 50}
            minPolarAngle={Math.PI / 4}
            zooming={false}
            rotateSpeed={0.5}
        />
    );
}

function Mee({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);

    const mesh = useRef();


    let raise = false;
    useFrame(() => {
        if(mesh) {
            const hearth = mesh.current.children.find(i => i.name === 'armright');
            const armleft = mesh.current.children.find(i => i.name === 'armleft');
            hearth.rotation.z += raise ? 0.02 : -0.02;
            armleft.rotation.z += raise ? 0.02 : -0.02;

            if (hearth.rotation.z >= 1) {
                raise = false;
            }

            if (hearth.rotation.z <= 0) {
                raise = true;
            }
        }
    });
    return <primitive  ref={mesh} onPointerDown={onClick} object={gltf.scene} dispose={null} />
}


function Rotating ({url, fallback = null, onClick = () => {}}) {
    const mesh = useRef();



    // Rotate mesh every frame, this is outside of React without overhead
   // useFrame(() => (mesh.current.rotation.y += 0.005));

    return <group ref={mesh}>
        <group rotation={[MathUtils.degToRad(-90),0,0]}>

        </group>

        <group>



            <group>


                <Suspense fallback={null}>
                    <Asset url={"/models/fantasyWorld.glb"}/>
                </Suspense>
            </group>


            <group position={[-14,-1.4,-4]} rotation={[0,-0.35,0] } scale={[0.7,0.7,0.7]}>


                <Suspense fallback={null}>
                    <Asset url={"/models/bird.glb"}/>
                </Suspense>
            </group>


            <group position={[-14,-1.4,-4]} rotation={[0,-0.35,0] } scale={[0.7,0.7,0.7]}>


                <Suspense fallback={null}>
                    <Asset url={"/models/modeltilt.gltf"}/>
                </Suspense>
            </group>


        </group>


    </group>
}


const FactView = ({counter, onNext, onClose}) => {
    return <Overlay visible={counter != 0}>
        {facts[counter - 1]}
        <div className={"next"} onClick={onNext}>>></div>
        <div className={"close"} onPointerDown={onClose}>x</div>
    </Overlay>
};

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({next, }) => {
    const rotationX = useControl('Rotation X', { type: 'number' , min:-5, max:150});


    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);

    return  <scene>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>

        <group position={[rotationX,-0.5,1.47]}>
            {/* <fog attach="fog" args={["white", 0, 40]} />*/}
            <ambientLight intensity={0.4} />
            <directionalLight
                castShadow
                position={[2.5, 8, 5]}
                intensity={1.5}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
            <pointLight position={[-10, 0, -20]} color="red" intensity={2.5} />
            <pointLight position={[0, -10, 0]} intensity={1.5} />

                <Rotating/>

        </group>
        <OControls />
        {/*<Controls />*/}
    </scene>
}

function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function Me(prop) {
    const [counter, setCounter] = React.useState(0);

    const next = () => {
        setCounter((counter) => {
            if(counter < facts.length) {
                return counter + 1;
            }

            return 0;
        });
    };


    return <div>
        <Controls.Provider>

        <Controls.Canvas colorManagement shadowMap camera={{ position: [1.6, -1.6, 0.9],rotation:[Math.PI / 3,0,0], fov: 40 }} style={{ height: "100vh", width: "100vw", background: "black"}}>
            <TheScene next={next}/>
            {/*<PostProcessing/>*/}
        </Controls.Canvas>


        <DownCanva onClick={() => {
            window.location = '/';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>9</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>
        <FactView counter={counter} onNext={next} onClose={() => setCounter(() => 0)}/>
            <Controls />
        </Controls.Provider>
    </div>
}

export default Me;
/*

            <group position={[2,0.8,2]} scale={[0.1, 2, 1]}>
              <mesh>
                  <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                  <meshStandardMaterial   attach="material" color={  'orange'} />
              </mesh>
            </group>
            <group position={[2,-3.2,2]} scale={[0.1, 2, 1]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'orange'} />
                </mesh>
            </group>

            <group position={[2,-1.2,4]} scale={[0.1, 1, 2]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'orange'} />
                </mesh>
            </group>
            <group position={[2,-1.2,-0]} scale={[0.1, 1, 2]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'orange'} />
                </mesh>
            </group>

            <group position={[2,0.65,-0]} rotation={[0.7,0,0]} scale={[0.1, 0.5, 1]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'pink'} />
                </mesh>
            </group>

            <group position={[2,0.65,4]} rotation={[-0.7,0,0]} scale={[0.1, 0.5, 1]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'green'} />
                </mesh>
            </group>

            <group position={[2,-3.25,0]} rotation={[-0.7,0,0]} scale={[0.1, 0.5, 1]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'yellow'} />
                </mesh>
            </group>

            <group position={[2,-3.25,4]} rotation={[0.7,0,0]} scale={[0.1, 0.5, 1]}>
                <mesh>
                    <octahedronBufferGeometry  attach="geometry" args={[1.0,0]}/>
                    <meshStandardMaterial   attach="material" color={  'pink'} />
                </mesh>
            </group>

            <group position={[2,-1.1,2]} scale={[1, 1, 1]}>
                <mesh>
                    <sphereBufferGeometry  attach="geometry" args={[0.5,0]}/>
                    <meshStandardMaterial   attach="material" color={  'orange'} />
                </mesh>
            </group>



* */
