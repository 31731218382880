import React, { Suspense,  useCallback, useEffect, useRef, useMemo, useState} from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Controls from '../../components/Controls';
import {MathUtils, TextureLoader} from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import * as THREE from 'three'

//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;



const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;



const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const TouchCube = ({ size = 1, ...props }) => {
    //For debug the clickcontainer set visibilty to true
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial attach="material" visible={false} />
       </mesh>
    );
};

//      <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
//

const Plane = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

function AssetAni({ url }) {
    const gltf = useLoader(GLTFLoader, url);
    const ref = useRef();


    /*useEffect(() => {


        var position = Object.assign({}, spine.position);
        var ehs = true;

    },[]);*/


    useFrame(state => {
      //  const spine = gltf.scene.getObjectByName("BodySpine3");
      //  spine.position.y = (Math.sin(state.clock.getElapsedTime()) * 100000) / 15 + 4
    });

    return <primitive ref={ref}  object={gltf.scene} dispose={() => {console.log(ref)}} >

    </primitive>
}

function AssetAniMouth({ mouth, url }) {
    const gltf = useLoader(GLTFLoader, url);
    const ref = useRef();
    const texture = useMemo(() => new TextureLoader().load('/img/test.PNG'), []);
    const texture2 = useMemo(() => new TextureLoader().load('/img/test2.jpg'), []);
    const texture3 = useMemo(() => new TextureLoader().load('/img/test3.jpg'), []);
    const texture4 = useMemo(() => new TextureLoader().load('/img/test5.jpg'), []);

    texture.repeat.set(4,4);
    texture.wrapS = THREE.RepeatWrapping;
    texture.wrapT = THREE.RepeatWrapping;
    texture2.repeat.set(10,10);
    texture2.wrapS = THREE.RepeatWrapping;
    texture2.wrapT = THREE.RepeatWrapping;
    texture3.repeat.set(10,10);
    texture3.wrapS = THREE.RepeatWrapping;
    texture3.wrapT = THREE.RepeatWrapping;
    texture4.repeat.set(10,10);
    texture4.wrapS = THREE.RepeatWrapping;
    texture4.wrapT = THREE.RepeatWrapping;
    const [active, setActive] = React.useState(false);



    var test = false;



    useFrame(state => {
          const screenone = gltf.scene.getObjectByName("ScreenOne");
          const screentwo = gltf.scene.getObjectByName("ScreenTwo");
          const screenthree = gltf.scene.getObjectByName("ScreenThree");

          if(!test) {
              console.log(screenone);
              screenone.material = new THREE.MeshPhongMaterial( { map: texture } );
              screenthree.material = new THREE.MeshPhongMaterial( { map: texture2 } );
              screentwo.material = new THREE.MeshPhongMaterial( { map: texture3 } );
              test = true;
          }



        //  spine.position.y = (Math.sin(state.clock.getElapsedTime()) * 100000) / 15 + 4
    });

    return <primitive ref={ref} onClick={(e) => {
        console.log(e);
    }}

     object={gltf.scene} dispose={() => {console.log(ref)}} >

    </primitive>
}


const Rotating = ({url, fallback = null, colorbear = () => {}, chameleon = () => {}}) => {
    const { camera, size, scene, gl} = useThree();
    const mesh = useRef();

    useEffect(() => {
        camera.position.add(new THREE.Vector3(0,0,-3))
    },[]);

    // Rotate mesh every frame, this is outside of React without overhead
   // useFrame(() => (mesh.current.rotation.y += 0.005));

    return <group ref={mesh}>


        <group rotation={[0,MathUtils.degToRad(-120),0]}>
            <group>
                <TouchCube onPointerDown={chameleon} size={1} scale={[8,5,0.1]}  position={[0,3.5,-4]} />
                <TouchCube onPointerDown={colorbear} size={1} scale={[8,5,0.1]}  position={[0,3.5,4]} />

                <Suspense fallback={fallback}>
                    <AssetAniMouth url={url} onClick={() => {}}/>
                </Suspense>
            </group>
        </group>


    </group>
}

/*
 <group rotation={[MathUtils.degToRad(-90),0,0]}>
            <Plane size={10}/>
        </group>
* */
const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({chameleon, colorbear }) => {

    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);

    return  <scene>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>
        { /* Red */ }
        <pointLight position={[-1.52, 0.985, -2.3]} color={0xFF0000} distance={4.4} intensity={2.6}/>

        <group position={[0,-2.5,0]}>
            <Rotating  chameleon={chameleon} colorbear={colorbear} url="/models/Stage.glb" fallback={cube}/>
        </group>
        <Controls />
    </scene>
}

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


const FactView = ({visible, onClose, ...prop}) => {
    return <Overlay visible={visible}>
        {prop.children}
        <div className={"close"} onPointerDown={onClose}>x</div>
    </Overlay>
};


function David(prop) {
//    <PostProcessing/>
    const [first,setFirst] = useState(false);
    const [second,setSecond] = useState(false);

    return <div>
        <Canvas style={{ height: "100vh", width: "100vw", background: "black"}}>
            <TheScene chameleon={() => setFirst(i => !i)} colorbear={() => setSecond(i => !i)} />
        </Canvas>


        <DownCanva onClick={() => {
            window.location = '/medusa';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>4</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <FactView onClose={() => setFirst(false)} visible={first}>
            <p>Credits:</p>
            {/*<a href="https://twitter.com/saigon_3d">Saigon3D (NSFW)</a>*/}
            <a href="https://www.instagram.com/kappaxbeta/">Kappaxbeta</a>
        </FactView>
        <FactView onClose={() => setSecond(false)} visible={second}>
            <p>Credits:</p>
            <a href="https://www.instagram.com/kingbeargermany/">Jens Bösche</a>
        </FactView>


        <Version>V0.0.2Alpha</Version>


    </div>
}

export default David;
