
import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree, useUpdate} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
import {VRCanvas, DefaultXRControllers} from "@react-three/xr";
//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;



const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({next, }) => {

    return  <scene>

        <group position={[0,0,0]}>



            <group position={[0, -0.5, 0]}>

                <GalaxieRenderer/>

            </group>
        </group>

    </scene>
}

function Particles({ pointCount }) {
    const [positions, colors] = useMemo(() => {
        let positions = [],
            colors = []
        for (let i = 0; i < pointCount; i++) {
            positions.push(5 - Math.random() * 10)
            positions.push(5 - Math.random() * 10)
            positions.push(5 - Math.random() * 10)
            colors.push(1)
            colors.push(0.5)
            colors.push(0.5)
        }
        return [new Float32Array(positions), new Float32Array(colors)]
    }, [pointCount])

    const attrib = useRef()
    const hover = useCallback(e => {
        e.stopPropagation()
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 1
        attrib.current.array[e.index * 3 + 2] = 1
        attrib.current.needsUpdate = true
    }, [])

    const unhover = useCallback(e => {
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 0.5
        attrib.current.array[e.index * 3 + 2] = 0.5
        attrib.current.needsUpdate = true
    }, [])

    return (
        <points onPointerOver={hover} onPointerOut={unhover}>
            <bufferGeometry attach="geometry">
                <bufferAttribute attachObject={["attributes", "position"]} count={positions.length / 3} array={positions} itemSize={3} />
                <bufferAttribute ref={attrib} attachObject={["attributes", "color"]} count={colors.length / 3} array={colors} itemSize={3} />
            </bufferGeometry>
            <pointsMaterial attach="material" vertexColors={true} size={10} sizeAttenuation={false} />
        </points>
    )
}


function GalaxieRenderer() {
    const objRef = useRef();
    useFrame((state) => {
        if(objRef.current) {
            objRef.current.rotation.y += 0.01;
            objRef.current.needsUpdate  = true;
        }
    });


    const attrib = useRef()
    const hover = useCallback(e => {
        e.stopPropagation()
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 1
        attrib.current.array[e.index * 3 + 2] = 1
        attrib.current.needsUpdate = true

    }, [])

    const unhover = useCallback(e => {
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 0.5
        attrib.current.array[e.index * 3 + 2] = 0.5
        attrib.current.needsUpdate = true
    }, [])
    const parameters = {};

    parameters.count = 100000;
    parameters.size = 0.1;
    parameters.radius = 5;
    parameters.branches = 3;
    parameters.spin = 1;
    parameters.randomness = 0.2;
    parameters.randomnessPower = 3;
    parameters.insideColor = '#000fff';
    parameters.outsideColor = '#de4646';



    const colorInside = new THREE.Color(parameters.insideColor);
    const colorOutside = new THREE.Color(parameters.outsideColor);


    const [positions, colors] = useMemo(() => {
        let positions = [],
            colors = [];

        for(let i = 0; i < parameters.count; i++)
        {
            // Position
            const i3 = i * 3;

            const radius = Math.random() * parameters.radius;

            const spinAngle = radius * parameters.spin;
            const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2;

            const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius;
            const randomY = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius;
            const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius;

            positions[i3    ] = Math.cos(branchAngle + spinAngle) * radius + randomX;
            positions[i3 + 1] = randomY;
            positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ;

            // Color
            const mixedColor = colorInside.clone();
            mixedColor.lerp(colorOutside, radius / parameters.radius);

            colors[i3    ] = mixedColor.r;
            colors[i3 + 1] = mixedColor.g;
            colors[i3 + 2] = mixedColor.b;
        }
        return [new Float32Array(positions), new Float32Array(colors)]
    }, []);
    //const positions = new Float32Array(parameters.count * 3);
    //const color = new Float32Array(parameters.count * 3);

    return <>
        <points ref={objRef} onPointerOver={hover} onPointerOut={unhover}>

            <bufferGeometry attach="geometry">
                <bufferAttribute attachObject={["attributes", "position"]} count={positions.length / 3} array={positions} itemSize={3} />
                <bufferAttribute ref={attrib} attachObject={["attributes", "color"]} count={colors.length / 3} array={colors} itemSize={3} />
            </bufferGeometry>

            <pointsMaterial attach="material" size={parameters.size} vertexColors={true}  ssizeAttenuation={false} deepWrite={false}  blending={THREE.AdditiveBlending}  />
            <meshBasicMaterial />
        </points>
    </>;
}
function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function Galaxie(prop) {

    return <div>
        <Canvas colorManagement shadowMap camera={{ position: [-5, 2, 10], fov: 60 }} style={{ height: "100vh", width: "100vw", background: "black"}}>

            <TheScene next={()=>{}}/>
            <Controls/>
            <PostProcessing/>

        </Canvas>


        <DownCanva onClick={() => {
            window.location = '/ballarina';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>11 Ex</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>

    </div>
}

export default Galaxie;
