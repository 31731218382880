import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import songs from "./songs";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
//import ChatBot from "aws-amplify-react/src/Interactions";
extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom:200px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;


const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const MobileBtn = styled.div`
  position: fixed;
  bottom: 130px;
  right: 130px;
  width: 30px;
  height: 30px;
  content: "Click";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;


const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

function Rotating ({url, fallback = null, onClick = () => {}}) {
    const mesh = useRef();

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.y += 0.005));

    return <group ref={mesh}>
        <group rotation={[MathUtils.degToRad(-90),0,0]}>
            <Plane size={10}/>
        </group>

        <group rotation={[0,MathUtils.degToRad(-120),0]}>
            <group position={[-2,0,-1]} scale={[0.1, 0.1, 0.1]}>
                <Suspense fallback={null}>
                    <Asset url={"/monstera.glb"}/>
                </Suspense>
            </group>
            <group scale={[0.25, 0.25, 0.25]}>
            <Suspense fallback={fallback}>
                <Asset url={url} onClick={onClick}/>
            </Suspense>
            </group>
        </group>


    </group>
}

var count = 0;
const Sound = ({delay = 0}) => {

    const {camera, scene} = useThree();
    if(count < 10) {
        count++
    // create an AudioListener and add it to the camera
    var listener = new THREE.AudioListener();
    camera.add( listener );

// create the PositionalAudio object (passing in the listener)
    var sound = new THREE.PositionalAudio( listener );

// load a sound and set it as the PositionalAudio object's buffer
    var audioLoader = new THREE.AudioLoader();
    audioLoader.load( songs[0].url, function( buffer ) {
        setTimeout(() => {
            sound.setBuffer( buffer );
            sound.setRefDistance( 20 );
            sound.play();
            sound.source.addEventListener("ended", () => {
                scene.remove(mesh);
                count--
            })
        }, delay)
    });


        const randdomFLoat = () => Math.floor(Math.random() * 4) / 10;
// create an object for the sound to play from
        const sphere = new THREE.SphereBufferGeometry( randdomFLoat(), 32, 16);
        const cube = new THREE.BoxBufferGeometry(randdomFLoat(), randdomFLoat(), randdomFLoat(),);
        const crone = new THREE.ConeBufferGeometry(randdomFLoat(), randdomFLoat());
        const icon = new THREE.IcosahedronBufferGeometry(randdomFLoat());
        const octahed = new THREE.OctahedronBufferGeometry(randdomFLoat());
        const forms = [sphere, cube, crone, icon, octahed];
        const colors = [0xff2200, 0x22ff00, 0xff0022, 0xCB4335, 0x2E86C1, 0x8E44AD, 0xFD07F5, 0xFFED00];
        var material = new THREE.MeshPhongMaterial({color: colors[Math.floor(Math.random() * 3)]});
        var mesh = new THREE.Mesh(forms[Math.floor(Math.random() * 4)], material);
        scene.add(mesh);
        const x = Math.random() * 4 - 2;
        const y = Math.random() * 4- 2;
        const z = Math.random() * 4 - 2;
        mesh.position.x = x;
        mesh.position.y = y;
        mesh.position.z = z;
// finally add the sound to the mesh
        mesh.add(sound);
    }
    return <></>;
}

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

function isIOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const TheScene = ({next, insert}) => {
    const { camera }= useThree();

    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);

    return  <scene>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>
        <Sound/>
        <Sound delay={5000} x={3}/>
        <group position={[0,-2.5,0]}>
            <Rotating onClick={next} url="/models/kappa2d.glb" fallback={cube}/>
        </group>
        <Controls />
    </scene>
}

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function OutOfControl(prop) {
    const [counter, setCounter] = React.useState(0);
    const [insert, setInsert] = React.useState(0);
    return <div>
        <Canvas style={{ height: "100vh", width: "100vw", background: "black"}}>
                <TheScene insert={insert}/>
                <PostProcessing/>
        </Canvas>

        <DownCanva  onClick={() => {
            window.location = '/cassandra';
        }}>
            KAPPAxBETA 3 KYLIEx
            <Notification >$</Notification>
           <Next>Next >></Next>
        </DownCanva>



        <Version>V0.0.3</Version>
        <Like/>

    </div>
}

export default OutOfControl;
