import React, {useState, useEffect,useRef,useMemo} from "react";
import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {Grommet, Box, Main, Image, Text, Grid, List} from 'grommet'
import { useSpring, config } from "react-spring"
import {animated} from "react-spring";

const theme = {
    "global": {
        "colors": {
            "background": {
                "light": "#ffffff",
                "dark": "#000000"
            }
        },
        "font": {
            "family": "-apple-system,\n         BlinkMacSystemFont, \n         \"Segoe UI\", \n         Roboto, \n         Oxygen, \n         Ubuntu, \n         Cantarell, \n         \"Fira Sans\", \n         \"Droid Sans\",  \n         \"Helvetica Neue\", \n         Arial, sans-serif,  \n         \"Apple Color Emoji\", \n         \"Segoe UI Emoji\", \n         \"Segoe UI Symbol\""
        }
    },
    "button": {
        "extend": [
            null
        ]
    }
}

const LogoStyle = styled.div`
    position: fixed;
    top: 10%;
    left: 10%;
    font-size: 2rem;
    border: solid 7px rgba(0,0,0, 0.6);
    padding: 5px;
    font-style: italic;
    color: rgba(0,0,0, 0.6);
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-weight: bold;
`;


const Headline = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
`;

const CallToAction = styled.div`
    position: fixed;
    font-weight: bold;
    font-size: 3rem;
    bottom: 30px;
    display: flex;
    right: 50px;
    background-color: yellow;
    font-family: "PixelMillennium";
    padding: 10px;
    border: double orange 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    > a:active, > a:visited, > a {
      text-decoration: none;
      color: black;
    }
`;

const BubbleContainer = styled.div`
   display: flex;
   max-width: 300px;
   min-width: 300px;
   background-color: mediumblue;
   color: white;
   border-radius: 10px;
   
   ${props => props.who !== "me" && css`

    background-color: green!important;
    color: white;
  `}
   
`;
const BubbleWrapper = styled(animated.div)`
   display: flex;
    ${props => props.who !== "me" && css` 
      margin-left: -50px;
  `}
      ${props => props.space && css`
       margin-bottom: 10px
  `}
         ${props => props.visable && css`

       display: none;
  `}
     
    
`;

const Landing = (props) => {
    const [state, setStage] = useState(0);
    const view = React.useRef();
    const up = () => {
        setStage(i => i+1);
    };
    return ( <Grommet full theme={theme}>
        <LogoStyle>κXβ</LogoStyle>
        <Box ref={view} fill="vertical" overflow="auto" align="center" flex="grow" onClick={up} >
            <p>here should be a list of things that went wrong</p>
        </Box>
        <CallToAction>Next</CallToAction>

    </Grommet>);
};
export default Landing;
