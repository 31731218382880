
import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree, useUpdate} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
import {VRCanvas, DefaultXRControllers} from "@react-three/xr";
//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;



const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

const TheScene = ({next, }) => {
    const ballerinaRef = useRef();

    useFrame(() => {
        ballerinaRef.current.rotation.y += 0.01;
    })

    return  <scene>

        <group position={[0,0,0]}>

            <group position={[0, -2, -0.5]}>
            { /* Pink */ }
            <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
            { /* Blau */ }
            <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
            { /* Grün */ }
            <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
            { /* Gelb */ }
            <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>
            </group>

            <group position={[0, -2, 0]}>

                <GalaxieRenderer/>
                <group rotation={[0,1.5,0]}>
                    <GalaxieRenderer/>
                </group>

                <group rotation={[0,3,0]} scale={[1.5,1.5,1.5]}>
                    <GalaxieRenderer/>
                </group>
                <group scale={[0.5,0.5,0.5]} ref={ballerinaRef}>
                <Suspense fallback={null}>
                    <Asset onClick={() => {}} url={'/models/ballarinasmall.glb'} />
                </Suspense>
                    <Cube size={6} position={[0,-2.5,0]}></Cube>
                </group>
            </group>
        </group>

    </scene>
}

function Particles({ pointCount }) {
    const [positions, colors] = useMemo(() => {
        let positions = [],
            colors = []
        for (let i = 0; i < pointCount; i++) {
            positions.push(5 - Math.random() * 10)
            positions.push(5 - Math.random() * 10)
            positions.push(5 - Math.random() * 10)
            colors.push(1)
            colors.push(0.5)
            colors.push(0.5)
        }
        return [new Float32Array(positions), new Float32Array(colors)]
    }, [pointCount])

    const attrib = useRef()
    const hover = useCallback(e => {
        e.stopPropagation()
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 1
        attrib.current.array[e.index * 3 + 2] = 1
        attrib.current.needsUpdate = true
    }, [])

    const unhover = useCallback(e => {
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 0.5
        attrib.current.array[e.index * 3 + 2] = 0.5
        attrib.current.needsUpdate = true
    }, [])

    return (
        <points onPointerOver={hover} onPointerOut={unhover}>
            <bufferGeometry attach="geometry">
                <bufferAttribute attachObject={["attributes", "position"]} count={positions.length / 3} array={positions} itemSize={3} />
                <bufferAttribute ref={attrib} attachObject={["attributes", "color"]} count={colors.length / 3} array={colors} itemSize={3} />
            </bufferGeometry>
            <pointsMaterial attach="material" vertexColors={true} size={10} sizeAttenuation={false} />
        </points>
    )
}


function GalaxieRenderer() {
    const objRef = useRef();
    const {gl} = useThree();
    console.log(gl);
    useFrame((state) => {
        if(objRef.current) {
            objRef.current.rotation.y += 0.001;
            objRef.current.needsUpdate  = true;
        }
    });


    const attrib = useRef()
    const hover = useCallback(e => {
        e.stopPropagation()
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 1
        attrib.current.array[e.index * 3 + 2] = 1
        attrib.current.needsUpdate = true

    }, [])

    const unhover = useCallback(e => {
        attrib.current.array[e.index * 3] = 1
        attrib.current.array[e.index * 3 + 1] = 0.5
        attrib.current.array[e.index * 3 + 2] = 0.5
        attrib.current.needsUpdate = true
    }, [])
    const parameters = {};

    parameters.count = 500;
    parameters.size = 0.1;
    parameters.radius = 5;
    parameters.branches = 3;
    parameters.spin = 1;
    parameters.randomness = 0.2;
    parameters.randomnessPower = 3;
    parameters.insideColor = '#000fff';
    parameters.outsideColor = '#de4646';



    const colorInside = new THREE.Color(parameters.insideColor);
    const colorOutside = new THREE.Color(parameters.outsideColor);


    const [positions, colors, randomness, scales] = useMemo(() => {
        let positions = [],
            colors = [],
            randomness = [],
            scales = [];


        for(let i = 0; i < parameters.count; i++)
        {
            // Position
            const i3 = i * 3;

            const radius = Math.random() * parameters.radius;

            const spinAngle = radius * parameters.spin;
            const branchAngle = (i % parameters.branches) / parameters.branches * Math.PI * 2;

            const randomX = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius;
            const randomY =  Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius;
            const randomZ = Math.pow(Math.random(), parameters.randomnessPower) * (Math.random() < 0.5 ? 1 : - 1) * parameters.randomness * radius;

            positions[i3    ] = Math.cos(i3 * 0.05)  * 0.01 * i; //+ randomX;
            positions[i3 + 1] = i3 * 0.01 ; // randomY;
            positions[i3 + 2] = Math.sin(i3  * 0.05)  * 0.01 * i;

            randomness[i3    ] = randomX;
            randomness[i3 + 1] = randomY;
            randomness[i3 + 2] = randomZ;
            // Color
            const mixedColor = colorInside.clone();
            mixedColor.lerp(colorOutside, i / parameters.count );

            colors[i3    ] = mixedColor.r;
            colors[i3 + 1] = mixedColor.g;
            colors[i3 + 2] = mixedColor.b;

            // Scale
            scales[i] = Math.random();

        }
        return [new Float32Array(positions), new Float32Array(colors),new Float32Array(randomness),new Float32Array(scales)]
    }, []);
    //const positions = new Float32Array(parameters.count * 3);
    //const color = new Float32Array(parameters.count * 3);

    return <>
        <points ref={objRef} onPointerOver={hover} onPointerOut={unhover} position={[0,-2,0]}>

            <bufferGeometry attach="geometry">
                <bufferAttribute attachObject={["attributes", "position"]} count={positions.length / 3} array={positions} itemSize={3} />
                <bufferAttribute ref={attrib} attachObject={["attributes", "color"]} count={colors.length / 3} array={colors} itemSize={3} />
                <bufferAttribute attachObject={["attributes", "randomness"]} count={positions.length / 3} array={randomness} itemSize={3} />
                <bufferAttribute attachObject={["attributes", "scales"]} count={colors.length / 3} array={scales} itemSize={1} />
            </bufferGeometry>

            <shaderMaterial attach="material" vertexColors={true} transparent={true} deepWrite={false}  blending={THREE.AdditiveBlending} uniforms={{ uTime: { value: 0 },
                uSize: { value: 30 * gl.getPixelRatio() }}} vertexShader={`uniform float uTime;
uniform float uSize;

attribute vec3 aRandomness;
attribute float aScale;

varying vec3 vColor;

void main()
{
    /**
     * Position
     */
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
                
    // Rotate
    float angle = atan(modelPosition.x, modelPosition.z);
    float distanceToCenter = length(modelPosition.xz);
    float angleOffset = (1.0 / distanceToCenter) * uTime;
    angle += angleOffset;
    modelPosition.x = cos(angle) * distanceToCenter;
    modelPosition.z = sin(angle) * distanceToCenter;

    // Randomness
    //modelPosition.xyz += aRandomness;

    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    gl_Position = projectedPosition;

    /**
     * Size
     */
    gl_PointSize = 0.2 * uSize;// uSize * aScale * 100.0;
    //gl_PointSize *= (1.0 / - viewPosition.z);

    /**
     * Color
     */
    vColor = color;
}`}  fragmentShader={`varying vec3 vColor;

void main()
{
    // // Disc
     //float strength = distance(gl_PointCoord, vec2(0.5));
   //  strength = step(0.5, strength);
   // strength = 1.0 - strength;

    // // Diffuse point
     float strength = distance(gl_PointCoord, vec2(0.5));
     strength *= 2.0;
     strength = 1.0 - strength;

    // Light point
    //float strength = distance(gl_PointCoord, vec2(0.5));
    //strength = 1.0 - strength;
    //strength = pow(strength, 10.0);

    // Final color
    vec3 color = mix(vec3(0.0), vColor, strength);
    gl_FragColor = vec4(color, 1.0);
}`}/>
            <meshBasicMaterial />
        </points>
    </>;
}
function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function Ballarina(prop) {

    return <div>
        <Canvas colorManagement shadowMap camera={{ position: [-5, 2, 10], fov: 60 }} style={{ height: "100vh", width: "100vw", background: "black"}}>

            <TheScene next={()=>{}}/>
            <Controls/>
            <PostProcessing/>

        </Canvas>


        <DownCanva onClick={() => {
            window.location = '/atomic';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>12</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>

    </div>
}

export default Ballarina;
