import React from "react";
import PropTypes from "prop-types";
import { VideoTexture } from "three";

const VideoPlan = props => {
  // instance a VideoTexture from a video element
  const texture = new VideoTexture(props.video);

  return (
    <mesh {...props}>
      <planeBufferGeometry
        attach="geometry"
        args={[props.width, props.height, 1, 1]}
      />
      <meshBasicMaterial attach="material" map={texture} />
    </mesh>
  );
};

VideoPlan.propType = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  video: PropTypes.any.isRequired
};

export default VideoPlan;
