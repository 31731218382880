import React from "react";
import styled from "styled-components";
import bg from "./bgSlow.gif";
import {NavLink} from "react-router-dom";

const LogoStyle = styled.div`
    position: absolute;
    top: 10%;
    left: 10%;
    font-size: 10rem;
    border: solid 7px rgba(0,0,0, 0.6);
    padding: 5px;
    font-style: italic;
    color: rgba(0,0,0, 0.6);
`;

const Bg = styled.div`
    height: 100vh;
    width: 100vw;
    background-image: url("${bg}");
    background-size: cover;
    background-repeat: no-repeat;;
    position: absolute;
    top: 0;
    left: 0;
`;

const CallToAction = styled.div`
    position: fixed;
    font-weight: bold;
    font-size: 3rem;
    bottom: 30px;
    display: flex;
    right: 50px;
    background-color: yellow;
    font-family: "PixelMillennium";
    padding: 10px;
    border: double orange 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    > a:active, > a:visited, > a {
      text-decoration: none;
      color: black;
    }
`;

const Landing = (props) => {
    return (<Bg>
        <h1><LogoStyle>κXβ</LogoStyle></h1>
            <CallToAction> <NavLink to={"/David"}>START ></NavLink></CallToAction>
    </Bg>);
};

export default Landing;
