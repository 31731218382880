import React, {Suspense, useRef, useMemo, useState} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader, extend} from "react-three-fiber";

import { ShaderMaterial } from "three"
import Controls from "../../components/Controls";

class DeformShader extends ShaderMaterial {
    constructor(options) {
        super({
            vertexShader: `uniform float amplitude;

attribute float displacement;

varying vec3 vNormal;
varying vec2 vUv;

void main() {

    vNormal = normal;
    vUv = ( 0.5 + amplitude ) * uv + vec2( amplitude );

    vec3 newPosition = position + amplitude * normal * vec3( displacement );
    gl_Position = projectionMatrix * modelViewMatrix * vec4( newPosition, 1.0 );

}`,
            fragmentShader: `varying vec3 vNormal;
varying vec2 vUv;

uniform vec3 color;
uniform sampler2D colorTexture;

void main() {

    vec3 light = vec3( 0.5, 0.2, 1.0 );
    light = normalize( light );

    float dProd = dot( vNormal, light ) * 0.5 + 0.5;

    vec4 tcolor = texture2D( colorTexture, vUv );
    vec4 gray = vec4( vec3( tcolor.r * 0.3 + tcolor.g * 0.59 + tcolor.b * 0.11 ), 1.0 );

    gl_FragColor = gray * vec4( vec3( dProd ) * vec3( color ), 1.0 );

}`
        });

        this.uniforms = {
            "amplitude": {value: 1.0},
            "color": {value: new THREE.Color(0xff2200)},
            "colorTexture": {value: new THREE.TextureLoader().load("/water.jpg")},
            "displace": {value: null}
        };

        this.uniforms[ "colorTexture" ].value.wrapS = this.uniforms[ "colorTexture" ].value.wrapT = THREE.RepeatWrapping;

    }

    set displace(value) {
        this.uniforms.displace.value = value
    }

    get displace() {
        return this.uniforms.displace.value;
    }

    set amplitude(value) {
        this.uniforms.amplitude.value = value
    }

    get amplitude() {
        return this.uniforms.amplitude.value;
    }

    set color(value) {
        this.uniforms.color.value = value
    }

    get color() {
        return this.uniforms.color.value;
    }
};

extend({DeformShader});


function Display (props) {
    const radius = 2, segments = 128, rings = 64;
    const material = useRef();
    const mesh = useRef();
    const geometry = new THREE.SphereBufferGeometry( radius, segments, rings );

    const displacement = new Float32Array(geometry.attributes.position.count);
    const noise = new Float32Array(geometry.attributes.position.count);
       for(var i = 0; i < noise.length; i ++) {
           noise[i] = Math.random() * 5;
       }

    geometry.setAttribute('displacement', new THREE.BufferAttribute( displacement, 1 ));

    useFrame(() => {
        var time = Date.now() * 0.01;

        mesh.current.rotation.y = mesh.current.rotation.z = 0.01 * time;

        material.current.amplitude = 2.5 * Math.sin(  mesh.current.rotation.y * 0.125 );
        material.current.color.offsetHSL( 0.0005, 0, 0 );

        for ( var i = 0; i < displacement.length; i ++ ) {

            displacement[ i ] = Math.sin( 0.1 * i + time );

            noise[ i ] += 0.5 * ( 0.5 - Math.random() );
            noise[ i ] = THREE.MathUtils.clamp( noise[ i ], 1, 5 );

            displacement[ i ] += noise[ i ];

        }

        mesh.current.geometry.attributes.displacement.needsUpdate = true;

    });
    return <group>
        <mesh visible geometry={geometry} ref={mesh} userData={{ test: 'hello' }} position={[0,0,0]} rotation={[0, 0, 0]}>
            <deformShader
                ref={material}
                attach="material"
            />
        </mesh>
    </group>
}

function Deform(prop) {

    return <div>
        <Canvas style={{ height: "100vh", width: "100vw", background: "black"}}>
            <scene>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <Display></Display>
                <Controls/>
            </scene>
        </Canvas>
    </div>
}

export default Deform;
