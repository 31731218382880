
import React from "react";
import styled from "styled-components";
import OrganicShape from "./organicShape";
import {Text, Heading} from "grommet"

 const Wrapper = styled.div`
  margin: 50px;
  width: calc(100vw - 100px);
  height: calc(100vh - 100px);
  border-radius: 50px;
  background-color: rgb(249,249,249);
  position: relative;
`;

const Main = styled.div`
  position: absolute;
  top: 0;
  left: 200px;
  border-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
   width: calc(100vw - 300px);
  height: calc(100vh - 100px);
  background-color: rgb(254,254,254);
  padding: 100px;
  box-sizing: border-box;
  
`;

const Nav = styled.div`
  position: absolute;
  top: 100px;
  left: 20px;
  border-radius: 50px;
   width: 100px;
  
  
`;

const NavItem = styled.div`
background-color: rgb(254,254,254);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
`;


const Card = styled.div`
  display: flex;
  min-height: 200px;
  border-radius: 25px;
  background-color: rgb(211,245,254);
`;

const Header = styled.div`
 margin-bottom: 20px;
 color: rgb(104,91,182);
 font-weight: bold;
`;

const Organic = () => {


    return (<div style={{backgroundColor: "rgb(238,240,242)"}}>
        <Wrapper>
            <Nav>
                <NavItem><Text>Hello</Text></NavItem>
            </Nav>
            <Main>
                <Header><Heading level={2}>Good Morning Jane</Heading></Header>
                <Card></Card>
            </Main>
        </Wrapper>
    </div>);
};

export default Organic;
