import React, {Suspense, useRef, useMemo, useState} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader, extend} from "react-three-fiber";

import { ShaderMaterial } from "three";

class MatMat extends ShaderMaterial {
    constructor(options) {
        super({
            vertexShader: `varying vec2 vUv;

void main()
{
    vUv = uv;
    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;
}`,
            fragmentShader: `    
uniform float time;

varying vec2 vUv;

void main( void ) {

    vec2 position = - 1.0 + 2.0 * vUv;

    float red = abs( sin( position.x * position.y + time / 5.0 ) );
    float green = abs( sin( position.x * position.y + time / 4.0 ) );
    float blue = abs( sin( position.x * position.y + time / 3.0 ) );
    gl_FragColor = vec4( red, green, blue, 1.0 );

}`
        });

        this.uniforms = {
            "time": {value: 1.0},
        };


    }
    set time(value) {
        this.uniforms.time.value = value
    }

    get time() {
        return this.uniforms.time.value;
    }
};

extend({MatMat});


function Mat (props) {
    const { clock} = useThree();
    const radius = 1;
    const material = useRef();
    const mesh = useRef();

    useFrame(() => {
        var delta = clock.getDelta();
        material.current.time = clock.elapsedTime;

    });

    return <group>
        <mesh visible ref={mesh} position={[0,0,0]} rotation={[0, 0, 0]}>
            <boxBufferGeometry attach="geometry" args={[1,1,1]} />
            <matMat
                ref={material}
                attach="material"
            />
        </mesh>
    </group>
}

export default Mat;
