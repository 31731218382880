import React, {useRef, Suspense, useMemo, useEffect} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader} from "react-three-fiber";
import Controls from "../../components/Controls";
import styled from "styled-components";

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;


const Cube = ({rot = 0.005, sX = 0, axis = "x",...props }) => {
    const { clock} = useThree();
    const material = useRef();

    let did = false;
    const ref = useRef();
    useFrame(() => {
        if(!did) {
            did = true;

            if(axis === "x")
            {
               // ref.current.rotation.x = sX;
            }
            else
            {
                //ref.current.rotation.y = sX;
            }
        }
        if(axis === "x")
        {
            //ref.current.rotation.x += rot;
        }
        else
        {
            //ref.current.rotation.y += rot;
        }
        material.current.time = clock.elapsedTime;

    });
    return <mesh ref={ref} {...props}>
        <boxBufferGeometry attach="geometry" args={[0.05,0.05,0.05]} />
        <colorMat
            ref={material}
            attach="material"
        />
    </mesh>;
};
function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function make2DArray(col, rows) {
    const arr = new Array(cols);
    for(let i = 0; i < arr.length; i++) {
        arr[i] = new Array(rows);

    }
    return arr;
}

let grid;
let cols;
let rows;
let resolution = 4;

function countNeighours(grid, x, y) {
    let sum = 0;

    for(let i = -1; i < 2; i++) {
        for(let j = -1; j < 2; j++) {
            let col = (x + i + cols) % cols;
            let row = (y + j + rows) % rows;

            sum += grid[col][row];
        }
    }

    sum -= grid[x][y];
    return sum
}


const Scene = () => {
    const ref = useRef();
    useFrame(() => {
       // ref.current.rotation.z += 0.001;
    });

    cols = 100;
    rows = 120;
    grid = make2DArray(cols, rows);


    for(let i = 0; i < cols; i++) {
        for(let j = 0; j < rows; j++) {
            grid[i][j] = Math.floor(Math.random() * 3);
        }
    }

    const [draw, setDraw] = React.useState(grid);
    React.useEffect(() => {
        const interval = setInterval(() => {



            setDraw((grid) => {
                let next = make2DArray(cols, rows);

                for(let i = 0; i < cols; i++) {
                    for(let j = 0; j < rows; j++) {
                        // Count live neighours
                        let sum = 0;
                        let neighours = countNeighours(grid, i, j);

                        let state = grid[i][j];

                        if(state === 0 && neighours == 3) {
                            next[i][j] = 1;
                        } else if(state === 1 && (neighours < 2 || neighours > 3)) {
                            next[i][j] = 0;
                        } else {
                            next[i][j] = state
                        }
                    }

                }
                return next;
            });
        }, 50);
        return () => clearInterval(interval);
    },[]);


    console.log(grid);

    return   <group ref={ref}>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>
        {draw.map( (i,c) => {
            return i.map((j, cb) => {
                if(j === 1) {
                    return (<Cube key={'' + c + cb} position={[(0.051 * c) - 5, (0.051 * cb) - 4, 0]}/>);
                }
            })
        })}
    </group>;
};

const Cameleon = (prop) => {

    return <div>
        <Canvas orthographic camera={{zoom: 120}} style={{ height: "100vh", width: "100vw", background: "black"}}>
            <scene>
                <ambientLight position={[0, 0, 2]}/>
<Scene/>


                <PostProcessing/>
            </scene>
        </Canvas>
        <DownCanva onClick={() => {
            window.location = '/marble';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>9</Notification>
            <Next>Next >></Next>
        </DownCanva>
    </div>;
};

export default Cameleon;
