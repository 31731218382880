import React, {Suspense} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader} from "react-three-fiber";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

console.log(window.artoolkit);
Object.assign(window.ARjs.Context.prototype, THREE.EventDispatcher.prototype);
Object.assign(window.THREEx.ArBaseControls.prototype, THREE.EventDispatcher.prototype );
console.log(window);
window.THREE = THREE;
let ARjs = window.THREEx;
console.log(ARjs);



function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

function Display() {
    const { camera, gl, scene } = useThree();
    let arToolkitSource = new ARjs.ArToolkitSource({
        // to read from the webcam
        sourceType : 'webcam',

        // // to read from an image
        // sourceType : 'image',
        // sourceUrl : THREEx.ArToolkitContext.baseURL + '../data/images/img.jpg',

        // to read from a video
        // sourceType : 'video',
        // sourceUrl : THREEx.ArToolkitContext.baseURL + '../data/videos/headtracking.mp4',
    });
    scene.visible = false;

    let arToolkitContext =  new ARjs.ArToolkitContext({
        detectionMode: 'mono',
        canvasWidth: 480,
        canvasHeight: 640,
    }, {
        sourceWidth: 480,
        sourceHeight: 640,
    });


    function onResize(){
        arToolkitSource.onResizeElement();
        arToolkitSource.copyElementSizeTo(gl.domElement);
        if( arToolkitContext.arController !== null ){
            arToolkitSource.copyElementSizeTo(arToolkitContext.arController.canvas)
        }
    }

    arToolkitSource.init(function onReady(){
        // use a resize to fullscreen mobile devices
        setTimeout(function() {
            onResize()
        }, 1000);
    });
    arToolkitContext.init(function onCompleted(){
        // copy projection matrix to camera
        camera.projectionMatrix.copy( arToolkitContext.getProjectionMatrix() );
    });

    // init controls for camera
    var markerControls = new ARjs.ArMarkerControls(arToolkitContext, camera, {
        type : 'nft',
        descriptorsUrl : 'qr/qrcode',
        changeMatrixMode: 'cameraTransformMatrix'
    });

    window.addEventListener('resize', function(){
        onResize()
    });
    useFrame(() => {
        if( arToolkitSource.ready === false )	return;
        arToolkitContext.update( arToolkitSource.domElement );

        // update scene.visible if the marker is seen

        if(camera.visible){
            scene.visible = true;
            console.log("DETECTED")
        }else{
            scene.visible = false;
            console.log("CANT DETECT")
        }
    });
    return    <Suspense fallback={null}>
        <Asset url={"/saule.glb"}/>
    </Suspense>
}
function AR(prop) {

    return <div>
            <Canvas>
                <scene>
                    <Display></Display>
                </scene>
            </Canvas>
    </div>
}
/*
*  <Canvas vr={true}  onCreated={({ gl }) => document.body.appendChild(VRButton.createButton(gl))} style={{ height: "100vh", width: "100vw", background: "black"}}>

        </Canvas>
* */

export default AR;
