
import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree, useUpdate} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
import {VRCanvas, DefaultXRControllers} from "@react-three/xr";
//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;



const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

const TheScene = ({next, }) => {
    //const ballerinaRef = useRef();

    useFrame(() => {
        //ballerinaRef.current.rotation.y += 0.01;
    })

    return  <scene>

        <group position={[0,0,0]}>


            <group position={[0, 0, 0]}>

                <GalaxieRenderer/>


            </group>
        </group>

    </scene>
}


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


function GalaxieRenderer() {
    const wD = useWindowDimensions();
    const objRef = useRef();
    const mat = useRef();
    const uniforms = useMemo(() => ({
        time: { type: "f", value: 0 },
        resolution: { type: "v4", value: new THREE.Vector4() },
        uvRate1: {
            value: new THREE.Vector2(1, 1)
        }
    }), []);
    const {gl,camera} = useThree();
    let time  = 0;
    useFrame((state) => {
        if(objRef.current) {

            objRef.current.lookAt(camera.position);
            time +=0.05;
            objRef.current.material.uniforms.time.value = time;
        }


    });



    return <>
        <mesh ref={objRef} position={[0,0.5,0]} scale={wD.width > 650 ? wD.width > 1300 ? [3,3,1] : [2,2,1] : [1,1,1]}>

            <planeBufferGeometry attach="geometry" args={[5, 5,1,1]} />

            <shaderMaterial attach="material" vertexColors={true} ref={mat} side={THREE.DoubleSide} transparent={true} deepWrite={false}  blending={THREE.AdditiveBlending} uniforms={uniforms} vertexShader={`
uniform float time;
varying vec2 vUv;
varying vec3 vPosition;
uniform vec2 pixels;
float PI = 3.141592653589793238;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}
`}  fragmentShader={`
uniform float time;
uniform float progress;
uniform sampler2D texture1;
uniform vec4 resolution;
varying vec2 vUv;
varying vec3 vPosition;
float PI = 3.141592653589793238;

float sphere(vec3 p){
    return length(p)-0.5;
    
}

mat4 rotationMatrix(vec3 axis, float angle) {
    axis = normalize(axis);
    float s = sin(angle);
    float c = cos(angle);
    float oc = 1.0 - c;
    
    return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                0.0,                                0.0,                                0.0,                                1.0);
}

vec3 rotate(vec3 v, vec3 axis, float angle) {
mat4 m = rotationMatrix(axis, angle);
return (m * vec4(v, 1.0)).xyz;
}

float sdBox(vec3 p, vec3 b) {
    vec3 q = abs(p) - b;
    return length(max(q,0.0)) + min(max(q.x,max(q.y,q.z)),0.0);
}

float sineCrazy(vec3 p) {
    return 1.0 - (sin(p.x) + sin(p.y) + sin(p.z)) / 5.;
}
float scene(vec3 p){
    //return sphere(p);
    
    vec3 p1 = rotate(p,vec3(1.,1.,1.),time/12.);
    float box = sdBox(p1,vec3(0.5,0.5,0.5));
    
    float scale = 30.0  + 20.0 * sin(time / 6.);
    return max( sphere(p), (0.85 - sineCrazy(p1 * scale)) / scale);
}

vec3 getNormal(vec3 p){
    vec2 o = vec2(0.001,0.);
    
    return normalize(
        vec3(
            scene(p + p.xyy) -  scene(p - p.xyy),
            scene(p + p.yxy) -  scene(p - p.yxy),
            scene(p + p.yyx) -  scene(p - p.yyx)
        )
    );
}

vec3 getColor (float amount) {
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}

vec3 getColorAmount (vec3 p) {
    float amount = clamp((1.5 - length(p)) / 2.0 ,0.0,1.0);
    vec3 col = 0.5 + 0.5 * cos(6.2813 * (vec3(0.2,0.0,0.0) + amount * vec3(1.0,1.0,0.5)));
    return col *amount;
}
    
void main(){
 vec2 newUV = vUv - vec2(0.5);//(vUv - vec2(0.5))*resolution.zw + vec2(0.5);
 
 vec3 camPos = vec3(0.,0.,2.);
 
 vec3 ray = normalize(vec3(newUV, -1.));
 
 vec3 rayPos = camPos;
 
 float curDist = 0.;
 float rayLen = 0.;
 
 vec3 light =vec3(15.,-2.,15.);
 
 vec3 color = vec3(0.);

 for(int i = 0; i<= 64; i++){
    curDist= scene(rayPos);
    rayLen +=0.6* curDist;
    
    rayPos=camPos + ray*rayLen;
    
    if(abs(curDist) <= 0.001 ||rayLen>10.0){
    
        vec3  n= getNormal(rayPos);
        
        float diff = dot(n,light);
        
        //color   = getColor(diff);
       // color = getColor(2.0*length(rayPos));
        break;
    }
  
     color += 0.04 * getColorAmount(rayPos);
    
 }
 
 
 gl_FragColor = vec4(color,1.);
 
 gl_FragColor.r -= abs(sin(time))*0.09;
}
`}/>
            <meshBasicMaterial />
        </mesh>
    </>;
}
function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />

    </effectComposer>;
}

// <afterimagePass attachArray="passes" />
//         <glitchPass attachArray="passes" renderToScreen />
function Atomic(prop) {

    return <div>
        <Canvas colorManagement shadowMap camera={{ position: [-5, 2, 1], fov: 60 }} style={{ height: "100vh", width: "100vw", background: "black"}}>

            <TheScene next={()=>{}}/>

            <PostProcessing/>

        </Canvas>


        <DownCanva onClick={() => {
            window.location = '/higherDimension';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>1C</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>

    </div>
}

export default Atomic;
