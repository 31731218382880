import React, {useRef, Suspense, useMemo, useEffect} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader} from "react-three-fiber";
import Controls from "../../components/Controls";
import { TextureLoader } from "three";
import {MathUtils} from "three";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import Mat from "./mat";
import ColorCube from "./ColorMat";
import styled from "styled-components";
function Bloom({ children }) {
    const { gl, camera, size } = useThree();
    const scene = useRef();
    const composer = useRef();
    useEffect(() => {
        composer.current = new EffectComposer(gl)
        composer.current.addPass(new RenderPass(scene.current, camera));
        composer.current.addPass(new UnrealBloomPass(new THREE.Vector2(size.width, size.height), 0.5, 1, 0));
    }, []);
    useEffect(() => void composer.current.setSize(size.width, size.height), [size]);
    useFrame(() => composer.current.render(), 1);
    return <scene ref={scene}>{children}</scene>
}

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


function Main({ children }) {
    const scene = useRef();
    const { gl, camera } = useThree();
    useFrame(() => void ((gl.autoClear = false), gl.clearDepth(), gl.render(scene.current, camera)), 2);
    return <scene ref={scene}>{children}</scene>
}

function Stars() {
    let group = useRef();
    let theta = 0
    useFrame(() => {
        // Some things maybe shouldn't be declarative, we're in the render-loop here with full access to the instance
        const r = 5 * Math.sin(THREE.Math.degToRad((theta += 0.01)))
        const s = Math.cos(THREE.Math.degToRad(theta * 2))
        group.current.rotation.set(r, r, r)
        group.current.scale.set(s, s, s)
    });

    const [geo, mat, coords] = useMemo(() => {
        const geo = new THREE.SphereBufferGeometry(1, 10, 10)
        const mat = new THREE.MeshBasicMaterial({ color: new THREE.Color('lightpink') })
        const coords = new Array(1000)
            .fill()
            .map(i => [Math.random() * 800 - 400, Math.random() * 800 - 400, Math.random() * 800 - 400])
        return [geo, mat, coords]
    }, []);

    return (
        <group ref={group}>
            {coords.map(([p1, p2, p3], i) => (
                <mesh key={i} geometry={geo} material={mat} position={[p1, p2, p3]} />
            ))}
        </group>
    )
}

const Cube = () => {
    const ref = useRef();
    useFrame(() => (ref.current.rotation.y += 0.005));


    return <mesh ref={ref}>
        <planeBufferGeometry attach="geometry" args={[1,1,1]} transparent/>
        <meshBasicMaterial  attach="material" color={"0xFFF"} side={THREE.DoubleSide}></meshBasicMaterial>
    </mesh>;
};

const Scene = () => {
    return <group>
        <pointLight position={[0, 0, 2]}/>
        <pointLight position={[0, 0, 1]}/>
        <Sphere size={0.5} position={[-1, 2.9, -1]}/>

        <Plane size={5} url={"/room/white.png"} position={[-2,0,0]}  transparent={false} rotation={[0,MathUtils.degToRad(-110),0]} />
        <Humen position={[0,-1.4,0]}/>
        <Plane size={5} url={"/room/white.png"} position={[2,0,1]} transparent={true}  rotation={[0,MathUtils.degToRad(120),0]} />
        <Floor size={20}  position={[-3,-2.5,0]} rotation={[MathUtils.degToRad(-90),0,0]}/>
    </group>
};


const Plane = ({ size = 1, url, transparent = false, background, ...props }) => {
    const texture = useMemo(() => new TextureLoader().load(url), []);

    return (
        <group {...props}>
            <mesh >
                <planeBufferGeometry attach="geometry" args={[size, size, size]} />
                <meshLambertMaterial attach="material" side={THREE.DoubleSide} transparent={true} >
                    <primitive attach="map" object={texture} />
                </meshLambertMaterial>
            </mesh>
            {/**<pointLight color="green" position={[-0.2]} intensity={5} distance={3}/>
            <mesh position={[0,0,0.12]}>
                <planeBufferGeometry attach="geometry" args={[size, size, size]} />
                <meshBasicMaterial  attach="material" color={"black"} side={THREE.DoubleSide}></meshBasicMaterial>
            </mesh>*/}
        </group>
    );
};



const PlaneHole = ({ size = 1, url, transparent = false, background, ...props }) => {
    const texture = useMemo(() => new TextureLoader().load(url), []);

    return (
        <group {...props}>
            <mesh >
                <planeBufferGeometry attach="geometry" args={[size, size, size]} />
                <meshLambertMaterial attach="material" side={THREE.DoubleSide} transparent={true} >
                    <primitive attach="map" object={texture} />
                </meshLambertMaterial>
            </mesh>
        </group>
    );
};
const Humen = ({ size = 1, url, transparent = false, ...props }) => {
    const texture = useMemo(() => new TextureLoader().load("/room/humen.png"), []);

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[1, 2, 1]} />
            <meshLambertMaterial attach="material" color={"white"} side={THREE.DoubleSide} transparent={true} >
                <primitive attach="map" object={texture} />
            </meshLambertMaterial>
        </mesh>
    );
};

const Floor = ({ size = 1, transparent = false, ...props }) => {
    const { clock} = useThree();
    const material = useRef();

    useFrame(() => {
        material.current.time = clock.elapsedTime + 10.0;
    });
    return (
        <group {...props}>
            <mesh position={[0,0,0]}>
                <planeBufferGeometry attach="geometry" args={[size, size, size]} />
                <colorMat
                    ref={material}
                    attach="material"
                />
            </mesh>

            <pointLight distance={6} color={"green"} intensity={10}  position={[0,-10,0]}/>

        </group>
    );
};


const Sphere = ({ size = 1, ...props }) => {
    //  <meshBasicMaterial  attach="material" color={"orange"}></meshBasicMaterial>
    const { clock} = useThree();
    const material = useRef();

    useFrame(() => {
        material.current.time = clock.elapsedTime + 100.0;
    });
    return (
        <mesh {...props}>
            <sphereBufferGeometry attach="geometry" args={[size, 15, 15]} />

            <matMat
                ref={material}
                attach="material"
            />
        </mesh>
    );
};


const Room = (prop) => {
    return <div>
        <Canvas  camera={{ }}  style={{ height: "100vh", width: "100vw", background: "black"}}>
            <Main>
                <Scene/>
                <PlaneHole size={10} url={"/room/white-hole.png"} transparent={true} position={[0.8, 0.25,-2.2]} rotation={[0,0,0]} />

            </Main>
            <Bloom>
                <Plane size={5} url={"/room/white.png"} position={[2,0,1]} transparent={true}  rotation={[0,MathUtils.degToRad(120),0]} />

                <Sphere size={0.5} position={[-1, 2.9, -1]}/>
                <PlaneHole size={10} url={"/room/white-hole.png"} transparent={true} position={[0.8, 0.25,-2.2]} rotation={[0,0,0]} />
                <pointLight position={[0,-0.5,0]} color={"blue"} intensity={5} distance={4}/>
              <Humen position={[0.3,0,-2]}/>
                <Plane size={5} url={"/room/white.png"} position={[-2,0,0]}  rotation={[0,MathUtils.degToRad(-110),0]} />
            </Bloom>
            <Controls/>
        </Canvas>
    </div>;
};

export default Room;
