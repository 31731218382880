import { compose, lifecycle, withHandlers } from "recompose";
import { connect } from "react-redux";
import AppView from "./App.js";
import { toggleAppStart, setCols, setWidth } from "./AppState";

export default compose(
  connect(
    state => ({
      isAuthenticated: false
    }),
    {
      toggleAppStart,
      setCols,
      setWidth
    }
  ),
  withHandlers({
    handleWindowWidthChange: ({ setCols, setWidth }) => () => {
      const windowWidth = window.innerWidth;
      setWidth(windowWidth);

      if (windowWidth < 400) {
        setCols(1);
      } else {
        setCols(Math.floor((windowWidth - 320) / 320));
      }
    }
  }),
  lifecycle({
    componentWillMount() {
      this.props.toggleAppStart();
      window.addEventListener("resize", this.props.handleWindowWidthChange);
      this.props.handleWindowWidthChange();
    },
    componentWillUnmount() {
      window.removeEventListener("resize", this.props.handleWindowWidthChange);
    }
  })
)(AppView);
