import React, {useRef, Suspense, useState, useCallback, useMemo} from "react";
import * as THREE from 'three';

import {Canvas, useThree, useFrame, useLoader} from "react-three-fiber";
import Controls from "../../components/Controls";
import styled from "styled-components";
import {VRCanvas, DefaultXRControllers,Hands, useXR, useXREvent} from "@react-three/xr";
import Littleprince from "./Littleprince";
import Astroid from "./Astroid";
import Homecomet from "./Homecomet";



const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;




const Cube = ({rot = 0.005, sX = 0, axis = "x",...props}) => {
    const { clock} = useThree();
    const material = useRef();

    let did = false;
    const ref = useRef();
    useFrame(() => {
        if(!did) {
            did = true;

            if(axis === "x")
            {
                ref.current.rotation.x = sX;
            }
            else
            {
                ref.current.rotation.y = sX;
            }
        }
        if(axis === "x")
        {
            ref.current.rotation.x += rot;
        }
        else
        {
            ref.current.rotation.y += rot;
        }
        material.current.time = clock.elapsedTime;

    });
    return <mesh ref={ref} {...props}>
        <boxBufferGeometry attach="geometry" args={[0.5,3.5,0.1]} />
        <colorMat
            ref={material}
            attach="material"
        />

    </mesh>;
};

function Stars(prop) {
    const texture_1 = useLoader(THREE.TextureLoader, '/models/littleprince/star.png');
    const parameters = {};
    const attrib = useRef();
    parameters.count = 100;
    parameters.size = 50;


    const [positions, colors] = useMemo(() => {


        let positions = [],
            colors = [];

        for(let i = 0; i < parameters.count; i++)
        {
            const i3 = i * 3;

            positions[i3    ] = Math.random() * 100 - 50;
            positions[i3 + 1] = Math.random() * 100 - 50;
            positions[i3 + 2] = Math.random() * 100 - 50;

            colors[i3    ] = 0.3;
            colors[i3 + 1] =  0.3;
            colors[i3 + 2] =  0.3;
        }

        return   [new Float32Array(positions), new Float32Array(colors)]
    },[]);
  return  <points>

      <bufferGeometry attach="geometry">
          <bufferAttribute attachObject={["attributes", "position"]} count={positions.length / 3} array={positions} itemSize={3} />
          <bufferAttribute ref={attrib} attachObject={["attributes", "color"]} count={colors.length / 3} array={colors} itemSize={3} />
      </bufferGeometry>

      <pointsMaterial map={texture_1} attach="material" alphaMap={texture_1} transparent={true} size={parameters.size} vertexColors={false}  sizeAttenuation={false} deepWrite={false}  blending={THREE.AdditiveBlending}  />
      <meshBasicMaterial />
  </points>
}


const Scene = () => {
    const { isPresenting, player } = useXR();
    const [walk, setWalk]  = useState(false);
    const [arm, setArm]  = useState(false);
    const [head, setHead]  = useState(false);
    const { camera, gl } = useThree();
    const ref = useRef();
    /*useFrame(() => {
        //ref.current.rotation.z += 0.001;

    });

    React.useEffect(() => {
        if(gl.xr.isPresenting) {
            ref.current.position.z = -10
        } else {
            ref.current.position.z = 0
        }
    }, [gl.xr.isPresenting]);*/

    return   <group ref={ref}>
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        <pointLight position={[2, 3, -3.2]} color={'white'} distance={4.82} intensity={3.25}/>
        <Suspense fallback={null}>
        <Stars/>
        </Suspense>
        <Suspense fallback={null}>
            <Littleprince walk={walk} arm={arm} headMove={head} onPointerDown={() => {
                setWalk(i =>  !i);
                setArm(i =>  !i);
                setHead(i =>  !i);
            }}/>
        </Suspense>
        <Suspense fallback={null}>
            <Homecomet position={[0,-3,0]} scale={[3,3,3]}/>
        </Suspense>
    </group>;
};

const Cameleon = (prop) => {

    return <div>
        <VRCanvas orthographic camera={{zoom: 120}} style={{ height: "100vh", width: "100vw", background: "black"}}>
            <scene>
                <ambientLight position={[0, 0, 2]}/>
            <Scene/>
            <Controls/>
                <DefaultXRControllers />
            </scene>
        </VRCanvas>
        <DownCanva onClick={() => {
            window.location = '/stage';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>3</Notification>
            <Next>Next >></Next>
        </DownCanva>
    </div>;
};

export default Cameleon;
