import React, { useEffect, useRef } from "react";

// Custom hook to get user camera
export default function useCamera() {
  const ref = useRef();

  // fetching users camera feed, if not throw an error
  // on mobile you need to have a https connection
  useEffect(() => {
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_materials_video_webcam.html
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const constraints = {
        video: { width: 1280, height: 720, facingMode: "user" }
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(stream) {
          // apply the stream to the video element
          ref.current.srcObject = stream;
          ref.current.play();
        })
        .catch(function(error) {
          console.error("Unable to access the camera/webcam.", error);
        });
    } else {
      console.error("MediaDevices interface not available.");
    }
  }, []);

  return ref;
}
