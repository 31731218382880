import * as React from 'react'
import {
    BoxBufferGeometry,
} from 'three'

function create(type) {
    const El = type + 'BufferGeometry';
    return React.forwardRef(({ args, children, ...props }, ref) => (
        <mesh ref={ref} {...props}>
            <El attach="geometry" args={args} />
            {children}
        </mesh>
    ))
}

export const Box = create<typeof BoxBufferGeometry>('box');
