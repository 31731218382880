export const initialState = {
  isStart: false,
  cols: 1,
  width: window.innerWidth
};

export const TOGGLE_START = "APP/TOGGLE_START";
export const SET_COLS = "APP/SET_COLS";
export const SET_WIDTH = "APP/SET_WIDTH";

export const toggleAppStart = () => ({
  type: TOGGLE_START
});

export const setCols = cols => ({
  type: SET_COLS,
  payload: cols
});

export const setWidth = width => ({
  type: SET_WIDTH,
  payload: width
});

export default function AppReducer(state = initialState, { type, payload }) {
  switch (type) {
    case TOGGLE_START:
      return {
        ...state,
        isStart: !state.isStart
      };
    case SET_COLS:
      return {
        ...state,
        cols: payload
      };
    case SET_WIDTH:
      return {
        ...state,
        width: payload
      };
    default:
      return state;
  }
}
