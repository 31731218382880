import React, { useRef } from "react";
import { useThree, useFrame, extend } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

extend({ OrbitControls });

export default function Controls() {
  const controls = useRef();
  const { camera, gl } = useThree();
  // update the controls for damping
  useFrame(() => {
    if(controls.current){
      controls.current.update()
    }
   });

  // Enable damping and rotateSpeed to have a more natural feeling
  return (
    <orbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      enableDamping
      dampingFactor={0.1}
      rotateSpeed={0.5}
    />
  );
}
