import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
//import ChatBot from "aws-amplify-react/src/Interactions";
extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;

const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;


const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;


const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

function Rotating ({url, fallback = null, onClick = () => {}}) {
    const mesh = useRef();

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.y += 0.07));
    /*<group rotation={[MathUtils.degToRad(-90),0,0]}>
             <Plane size={10}/>
         </group>*/

    return <group ref={mesh}>

        <group rotation={[0,MathUtils.degToRad(-0),0]}>


            { /* Pink */ }
            <pointLight position={[2, 5, -1.2]} color={0xFFFFF} distance={4.82} intensity={1}/>


        </group>


    </group>
}


const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({next, }) => {

    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);

    return  <scene>
        <group position={[0,-0.7,-1]} scale={[0.35, 0.35, 0.35]}>
            <Suspense fallback={null}>
                <Asset url={"/models/scene-2.glb"}/>
            </Suspense>
        </group>
       <ambientLight />
        <group position={[0,-2.5,0]}>
            <Rotating onClick={next} url="/models/kappa2d.glb" fallback={cube}/>
        </group>
        <Controls />
    </scene>
}

function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>


    </effectComposer>;
}
/*
        <renderPass attachArray="passes" scene={scene} camera={camera} />
 <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
* */

function David(prop) {
    const [counter, setCounter] = React.useState(0);
    let audio = new Audio("/sound/robert.mp3");



    return <div>
        <Canvas style={{ height: "100vh", width: "100vw", background: "black"}}>
                <TheScene />
                <PostProcessing/>
            </Canvas>


        <DownCanva >
            KAPPAxBETA
            <Notification onClick={() => {
                audio.play();
            }}>5</Notification>
        </DownCanva>

        <Version>V0.0.1Robert</Version>
        <Like/>

    </div>
}

export default David;
