import React, { Suspense,  useCallback, useEffect, useRef, useMemo } from 'react'
import { Canvas } from "react-three-fiber";
import { useLoader, useFrame, extend, useThree} from 'react-three-fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Controls from '../../components/Controls';
import { MathUtils } from "three";
import styled, { css } from "styled-components";
import { EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm/postprocessing/GlitchPass";
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass";
import Like from "../../components/Like/Like";
import * as THREE from 'three'
//import ChatBot from "aws-amplify-react/src/Interactions";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass});

const DownCanva =  styled.div`
  position: fixed;
  color: white;
  font-family: "PixelMillennium";
  bottom: 130px;
  left: 130px;
  display: flex;
  border: solid white 2px;
  padding: 10px
`;

const Overlay = styled.div`
  position: fixed;
  font-size:25px;
  right: 75px;
  top: 100px;
  font-family: "PixelMillennium";
  max-width: 60%;
  color: white;
  display: none;
  background: black;
  border: double white 6px;
  padding: 10px;
  flex-direction: column;
  ${props => props.visible && css`
    display: flex;
  `}
  
   .next {
     display: block;
    border: double grey 6px;
    margin-top: 10px;
  }
  
  .next:hover {
    border-color: #ccc;
     color: mediumblue;
  }
  
  .close {
  position: absolute;
    width: 40px;
    height: 40px;
    top: -65px;
    right: -6px;
    display: flex;
    border: double grey 6px;
    margin-top: 10px;
    justify-content: center;
    vertical-align: middle;
    color: black;
    background: white;
  }
  .close:hover {
    border-color: #aaa;
     color: mediumblue;
  }
`;

const Notification = styled.div`
  position: absolute;
  right: -20px;
  top: -10px;
  width: 30px;
  height: 30px;
  content: "1";
  background-color: deeppink;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
`;

const Next = styled.div`
  position: absolute;
  right: -20px;
  bottom: -20px;
  width: 80px;
  height: 20px;
  content: "1";
  color: black;
  background-color: lime;
  display: flex;
  align-items: center;
  vertical-align: center;
  justify-content: center;
  cursor: pointer;
`;

const facts = [
    "The statue appears to show David after he has decided to fight Goliath, but before the battle has taken place, a moment between conscious choice and action – fight and escape.",
    "The statue of David is a symbol of the City of Florence, Italy, and is Michelangelo's most famous work of sculpture. It is often called The David.",
    "It is made of marble and is 17 feet tall. It is a statue of a shepherd, David, whose story is in the Bible. David fought a battle with a giant soldier called Goliath. He beat Goliath by knocking him down with a small stone from his slingshot. David later became King of Israel."
];

const Cube = ({ size = 1, ...props }) => {
    return (
        <mesh {...props}>
            <boxBufferGeometry attach="geometry" args={[size, size, size]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

const Plane = ({ size = 1, ...props }) => {

    return (
        <mesh {...props}>
            <planeBufferGeometry attach="geometry" args={[size, size,1,1]} />
            <meshStandardMaterial  attach="material" color={"0xFFFFFF"}></meshStandardMaterial>
        </mesh>
    );
};

function Asset({ url, onClick }) {
    const gltf = useLoader(GLTFLoader, url);
    return <primitive onPointerDown={onClick} object={gltf.scene} dispose={null} />
}

const easeInOutCubic = t => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1)
function Sphere({ position = [0, 0, 0], ...props }) {
    const ref = useRef()
    const factor = useMemo(() => 0.5 + Math.random(), [])
    useFrame(state => {
        const t = easeInOutCubic((1 + Math.sin(state.clock.getElapsedTime() * factor)) / 2)
        ref.current.position.y = position[1] + t * 4
        ref.current.scale.y = 1 + t * 3
    })
    return (
        <mesh ref={ref} position={position} {...props} castShadow receiveShadow>
            <sphereBufferGeometry attach="geometry" args={[0.5, 32, 32]} />
            <meshStandardMaterial attach="material" color="lightblue" roughness={0} metalness={0.1} />
        </mesh>
    )
}

function Spheres({ number = 20 }) {
    const ref = useRef()
    const positions = useMemo(() => [...new Array(number)].map(() => [3 - Math.random() * 6, Math.random() * 4, 3 - Math.random() * 6]), [])
    useFrame(state => (ref.current.rotation.y = Math.sin(state.clock.getElapsedTime() / 2) * Math.PI))
    return (
        <group ref={ref}>
            {positions.map((pos, index) => (
                <Sphere key={index} position={pos} />
            ))}
        </group>
    )
}

function Rotating ({url, fallback = null, onClick = () => {}}) {
    const mesh = useRef();

    // Rotate mesh every frame, this is outside of React without overhead
    useFrame(() => (mesh.current.rotation.y += 0.005));

    return <group ref={mesh}>
        <group rotation={[MathUtils.degToRad(-90),0,0]}>
            <Plane size={10}/>
        </group>

        <group rotation={[0,MathUtils.degToRad(-120),0]}>
            <group position={[2,0,-1]}>
            <Suspense fallback={null}>
                <Asset url={"/saule.glb"}/>
            </Suspense>
            </group>

            <group position={[-2,0,-1]} scale={[0.1, 0.1, 0.1]}>
                <Suspense fallback={null}>
                    <Asset url={"/monstera.glb"}/>
                </Suspense>
            </group>

            <Suspense fallback={fallback}>
                <Asset url={url} onClick={onClick}/>
            </Suspense>
        </group>


    </group>
}


const FactView = ({counter, onNext, onClose}) => {
    return <Overlay visible={counter != 0}>
        {facts[counter - 1]}
        <div className={"next"} onClick={onNext}>>></div>
        <div className={"close"} onPointerDown={onClose}>x</div>
    </Overlay>
};

const Version = styled.div`
  font-family: 'DJBGetDigital';
  color: white;
  position: fixed;
  top: 20px;
  right: 30px;
`;

const TheScene = ({next, }) => {

    let cube = (<Cube position={[0, 2,0]} scale={[1.5, 5,1.4]} />);

    return  <scene>
        { /* Pink */ }
        <pointLight position={[2, 5, -1.2]} color={0xFF00DC} distance={4.82} intensity={3.25}/>
        { /* Blau */ }
        <pointLight position={[2.1, 0.83, 0]} color={0x222FFF} distance={4} intensity={2.6}/>
        { /* Grün */ }
        <pointLight position={[-1.67, 0.35, 0]} color={0x28FF00} distance={5} intensity={2.2}/>
        { /* Gelb */ }
        <pointLight position={[-2.52, 3.985, -0.3]} color={0xFFF100} distance={4.4} intensity={2.6}/>

        <group position={[0,0,0]}>
            <fog attach="fog" args={["white", 0, 40]} />
            <ambientLight intensity={0.4} />
            <directionalLight
                castShadow
                position={[2.5, 8, 5]}
                intensity={1.5}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
            <pointLight position={[-10, 0, -20]} color="red" intensity={2.5} />
            <pointLight position={[0, -10, 0]} intensity={1.5} />
            <group position={[0, -3.5, 0]}>
                <mesh receiveShadow castShadow>
                    <boxBufferGeometry attach="geometry" args={[4, 1, 1]} />
                    <meshStandardMaterial attach="material" />
                </mesh>
                <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]} receiveShadow>
                    <planeBufferGeometry attach="geometry" args={[100, 100]} />
                    <shadowMaterial attach="material" transparent opacity={0.4} />
                </mesh>
                <Spheres />
            </group>
        </group>
        <Controls />
    </scene>
}

function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
    </effectComposer>;
}

function Marble(prop) {
    const [counter, setCounter] = React.useState(0);

    const next = () => {
        setCounter((counter) => {
            if(counter < facts.length) {
                return counter + 1;
            }

            return 0;
        });
    };


    return <div>
        <Canvas colorManagement shadowMap camera={{ position: [-5, 2, 10], fov: 60 }} style={{ height: "100vh", width: "100vw", background: "black"}}>
            <TheScene next={next}/>
            <PostProcessing/>
        </Canvas>


        <DownCanva onClick={() => {
            window.location = '/galaxy';
        }}>
            KAPPAxBETA
            <Notification onClick={() => {}}>9</Notification>
            <Next>Next >></Next>
        </DownCanva>

        <Version>V0.0.3</Version>
        <Like/>
        <FactView counter={counter} onNext={next} onClose={() => setCounter(() => 0)}/>

    </div>
}

export default Marble;
