import React, {useEffect, useMemo, useRef} from "react";
import {Canvas, extend, useFrame, useThree} from "react-three-fiber";

import useCamera from "../../components/hooks/useCamera";
import VideoPlan from "../../components/VideoPlane";
import Controls from "../../components/Controls";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {GlitchPass} from "three/examples/jsm/postprocessing/GlitchPass";
import {AfterimagePass} from "three/examples/jsm/postprocessing/AfterimagePass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import {PixelShader} from "three/examples/jsm/shaders/PixelShader";
import { AsciiEffect } from "three/examples/jsm/effects/AsciiEffect";
import * as THREE from "three";

extend({EffectComposer, RenderPass, GlitchPass, AfterimagePass, PixelShader, ShaderPass});
/**
 * Note: good candidate to add to a math helper collection
 * calculate dimensions to cover the viewport on position z 0
 * @param camera
 * @param viewport
 * @returns {{screenWidthValue: number, screenHeightValue: number}}
 */
function calcScreenDimensions(camera, viewport) {
    // https://gist.github.com/ayamflow/96a1f554c3f88eef2f9d0024fc42940f
    const cameraZ = camera.position.z;
    const planeZ = 0;
    const distance = cameraZ - planeZ;
    const aspect = viewport.width / viewport.height;
    const vFov = (camera.fov * Math.PI) / 180;
    const fullHeightFromZeroAtDistance = 2 * Math.tan(vFov / 2) * distance;
    const fullWidthFromZeroAtDistance = fullHeightFromZeroAtDistance * aspect;

    return {
        screenWidthValue: fullWidthFromZeroAtDistance,
        screenHeightValue: fullHeightFromZeroAtDistance
    };
}

function Art({cameraRef}) {
    const {camera, viewport} = useThree();

    const { screenHeightValue, screenWidthValue } = calcScreenDimensions(
        camera,
        viewport
    );

    return <group>
        <ambientLight />
        <VideoPlan video={cameraRef.current} height={screenHeightValue} width={screenWidthValue} />
        <Controls />
    </group>
}

function PostProcessing() {
    const composer = useRef();
    const { camera, size, scene, gl} = useThree();

    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1);

    return <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />
        <afterimagePass attachArray="passes" />
        <glitchPass attachArray="passes" renderToScreen />
        <shaderPass attachArray="passes" args={[PixelShader]} uniforms-pixelSize-value={32}
                    uniforms-resolution-value={new THREE.Vector2(window.innerWidth * window.devicePixelRatio, window.innerHeight * window.devicePixelRatio)}
                    uniforms-resolution-value-multiplyScalar={[window.devicePixelRatio]}
                    renderToScreen />
    </effectComposer>;
}

function Frame(prop) {
    const cameraRef = useCamera();

    return <div>
        <video ref={cameraRef} playsInline={true} style={{display: "none"}}/>
        <Canvas  style={{ height: "100vh", width: "100vw" }}>
           <scene>
               <Art cameraRef={cameraRef}/>
               <PostProcessing/>
           </scene>
        </Canvas>
    </div>
}
/*
*  <Canvas vr={true}  onCreated={({ gl }) => document.body.appendChild(VRButton.createButton(gl))} style={{ height: "100vh", width: "100vw", background: "black"}}>

        </Canvas>
* */

export default Frame;
